import React from 'react';
import { layoutContentStyle } from './layout.style';

type TLayoutContentProps = {
  children: React.ReactNode;
  height?: string | number;
};

export function LayoutContent({ children, height = '100%' }: TLayoutContentProps): JSX.Element {
  return (
    <div className={layoutContentStyle(height)}>{children}</div>
  );
}
