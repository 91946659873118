import React from 'react';
import { TLinkProps } from './link.props-type';
import { linkStyle } from './link.style';

export function Link({
  href = '',
  size = 'l',
  color = 'black',
  hoverColor = 'white',
  onClick,
  rel,
  target,
  children
}: TLinkProps): JSX.Element {
  const handleClick = (event: React.SyntheticEvent): void => {
    if (onClick) {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }
  };

  return (
    <a
      onClick={handleClick}
      className={linkStyle(size, color, hoverColor)}
      href={href}
      rel={rel}
      target={target}
    >
      {children}
    </a>
  );
}
