import React from 'react';
import {
  buttonsContainerStyle,
  buttonsContainerWrapperStyle
} from './buttons-container.style';

type TButtonsContainerProps = {
  children: Array<React.ReactNode>;
};

export function ButtonsContainer({ children }: TButtonsContainerProps): JSX.Element {
  return (
    <div className={buttonsContainerWrapperStyle}>
      <div className={buttonsContainerStyle}>
        {children}
      </div>
    </div>
  );
}
