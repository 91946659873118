import { extracIImageFileName } from './extract-image-file-name';

import { ImageFileName, ImagePath } from '../strict-strings';


function isResultImageFileName(result: unknown): result is ImageFileName {
  const tryExtractResult = extracIImageFileName(result as ImageFileName);

  return Boolean(tryExtractResult.projectId && tryExtractResult.type);
}

export function getImageNameFromPath(imagePath: ImagePath): ImageFileName {
  const result = imagePath.split('/').reverse()[0];

  const isImageName = isResultImageFileName(result);

  if (isImageName) {
    return new ImageFileName(result);
  }

  throw new Error(`
    Название файла ${imagePath.valueOf()} не соответствует формату PROJECTID_IMGTYPE_INDEX.EXT
  `);
}
