import { preventDefault } from 'common';
import React from 'react';
import { mobileHoverWrapperStyle } from './hover-wrapper.style';
import { THowerWrapperProps } from './prop.type';

export function HoverWrapperMobile({ children, onHover }: THowerWrapperProps): JSX.Element {
  const [isVisible, setIsVisible] = React.useState(false);

  const handleChangeVisibility = React.useCallback(
    () => setIsVisible((isVisiblePrev: boolean) => {
      const isVisibleNext = !isVisiblePrev;

      onHover && onHover(isVisibleNext ? 'enter' : 'leave');

      return isVisibleNext;
    }),
    [onHover]
  );

  return (
    <div
      className={mobileHoverWrapperStyle(isVisible)}
      onClick={handleChangeVisibility}
    >
      <div onClick={preventDefault}>
        {children}
      </div>
    </div>
  );
}
