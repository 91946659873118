import { analytics } from 'common';
import {
  EmptyBlock,
  Text
} from 'components';
import React from 'react';
import { PageWithForm } from '../page-with-form';

export function AllItemsSwipedPage(): JSX.Element {
  React.useEffect(() => {
    analytics.onViewPage('contact-endgame');
  }, []);

  return (
    <PageWithForm>
      <Text size="l">
        wow! you&rsquo;ve checked all the images we&nbsp;have to&nbsp;offer yet. let&rsquo;s get in&nbsp;touch!
      </Text>
      <EmptyBlock height="40px" />
      <Text size="l">
        send&nbsp;us your details and we&rsquo;ll get back to&nbsp;you shortly (like, really shortly)
      </Text>
      <EmptyBlock height="40px" />
    </PageWithForm>
  );
}
