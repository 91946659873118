import {
  allProjectsService,
  TProjects,
  useRx
} from 'common';
import { ProcessingWrapper } from 'components';
import React from 'react';
import { AllProjectsPageContent } from './all-projects-page-content';

export function AllProjectsPage(): JSX.Element {
  const { data, error } = useRx(allProjectsService.data$);

  React.useEffect(() => allProjectsService.getProjects(), []);

  return (
    <ProcessingWrapper error={error} data={data}>
      {(readyData: TProjects) => <AllProjectsPageContent projects={readyData} />}
    </ProcessingWrapper>
  );
}
