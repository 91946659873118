import React from 'react';
import { burgerIconStyle, crossIconStyle } from './burger-icon.style';
import { ReactComponent as Burger } from './Burger.svg';
import { ReactComponent as Cross } from './Cross.svg';

type TBurgerIconProps = {
  isOpened: boolean;
  isHovered: boolean;
};

export function BurgerIcon({ isOpened, isHovered }: TBurgerIconProps): JSX.Element {
  return isOpened
  ? <Cross className={crossIconStyle(isHovered)} />
  : <Burger className={burgerIconStyle(isHovered)} />;
}
