import React from 'react';
import { contentPageLayoutList } from './content-page-layout.style';

type TContentPageLayoutListProps = {
  children: React.ReactNode;
};

export function ContentPageLayoutList({ children }: TContentPageLayoutListProps): JSX.Element {
  return (
    <div className={contentPageLayoutList(React.Children.count(children))}>
      {React.Children.map(children, (child: React.ReactNode, index: number) => <div key={index}>{child}</div>)}
    </div>
  );
}
