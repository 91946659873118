import { keyframes } from '@emotion/css';
import { Colors, FontSizePx, LineHeightPx } from './enums';
import { TColor, TFontSize } from './types';

export const generalMenuWidthPX = 223;
export const headerMenuHeightPX = 118;
export const sheetWidthPX = 490;
export const copyrightHeightPX = 130;
export const mobileMediaQuery = '@media (max-width: 500px)';

export const fadeInKeyFrames = keyframes`
  0% { opacity: 0 },
  100% { opacity: 1 }
`;

export const stepsKeyframes = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    tranform: scale(1);
  }
`;

export const FontSizeMap: Record<TFontSize, string> = {
  'l': `${FontSizePx.L}px`,
  'm': `${FontSizePx.M}px`
};

export const LineHeightMap: Record<TFontSize, string> = {
  'l': `${LineHeightPx.L}px`,
  'm': `${LineHeightPx.M}px`
};

export const TextColorMap: Record<TColor, Colors> = {
  'black': Colors.BLACK,
  'red': Colors.RED,
  'white': Colors.WHITE
};

export const FontFamilyMap: Record<TFontSize, string> = {
  'l': 'HelveticaNeue-Bold',
  'm': 'HelveticaNeueCyr-Bold'
};
