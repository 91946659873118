import { UserSwipe } from 'common';
import { Text } from 'components';
import React from 'react';

type TResultSwitcherProps = {
  view: UserSwipe;
  selectedCount: number;
  rejectedCount: number;
};

export function ResultSwitcher({ view, selectedCount, rejectedCount }: TResultSwitcherProps): JSX.Element {
  return (
    <div>
      <Text
        align="center"
        cursor="pointer"
        color={view === UserSwipe.SELECT ? 'red' : 'white'}
      >
        your likes {selectedCount}
      </Text>
      <Text
        align="center"
        cursor="pointer"
        color={view === UserSwipe.REJECT ? 'red' : 'white'}
      >
        your secret desires {rejectedCount}
      </Text>
    </div>
  );
}
