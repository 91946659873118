import type { FingerPrintService } from './fingerprint.service';
import { ImagePath } from '../strict-strings';
import { ISyndicateTransport, syndicateMockTransport } from '../transport';
import { generatePhotoUrl } from '../utils';

type TPages = 'about us' | 'all projects' | 'contact' | 'contact-endgame' | 'its-a-match';

export class AnalyticsService {
  private fingerPrint: string = '';
  private transport: ISyndicateTransport;

  constructor(transport: ISyndicateTransport, private fingerPrintService: FingerPrintService) {
    this.transport = process.env.NODE_ENV === 'development' ? syndicateMockTransport : transport;

    void fingerPrintService.getFingerPrint().then((fingerPrint: string) => {
      this.fingerPrint = fingerPrint;
    });
  }

  onInit(device: 'mobile' | 'desktop'): void {
    if (!this.fingerPrint) {
      void this.fingerPrintService.getFingerPrint().then((fingerPrint: string) => {
        this.transport.sendAnalytics({
          fingerPrint,
          event: 'page: load',
          value: device
        });
      });
    } else {
      this.transport.sendAnalytics({
        fingerPrint: this.fingerPrint,
        event: 'page: load',
        value: device
      });
    }
  }

  onMenuOpen(): void {
    this.transport.sendAnalytics({
      fingerPrint: this.fingerPrint,
      event: 'click: menu open'
    });
  }

  onLikeChoose(photoPath: ImagePath): void {
    this.transport.sendAnalytics({
      fingerPrint: this.fingerPrint,
      event: 'choose: like',
      value: generatePhotoUrl(photoPath)
    });
  }

  onDislikeChoose(photoPath: ImagePath): void {
    this.transport.sendAnalytics({
      fingerPrint: this.fingerPrint,
      event: 'choose: dislike',
      value: generatePhotoUrl(photoPath)
    });
  }

  onProjectLabelToggle(projectId: string, state: 'show' | 'hide'): void {
    this.transport.sendAnalytics({
      fingerPrint: this.fingerPrint,
      event: 'project label: toggle',
      value: `${projectId}, ${state}`
    });
  }

  onProjectLabelClick(projectId: string): void {
    this.transport.sendAnalytics({
      fingerPrint: this.fingerPrint,
      event: 'project label: click',
      value: projectId
    });
  }

  onViewPage(page: TPages): void {
    this.transport.sendAnalytics({
      fingerPrint: this.fingerPrint,
      event: `view page: ${page}`
    });
  }
}
