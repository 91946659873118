import React from 'react';
import { BurgerIcon } from './burger-icon';
import { buttonStyle } from './menu-button.style';
import { Text } from '../../text';

type TMenuButtonProps = {
  onClick: VoidFunction;
  isOpened: boolean;
};

export function MenuButton({ onClick, isOpened }: TMenuButtonProps): JSX.Element {
  const [isHovered, setIsHovered] = React.useState(false);

  const handleSetHovered = React.useCallback(() => setIsHovered(true), []);
  const handleSetUnhovered = React.useCallback(() => setIsHovered(false), []);

  return (
    <button
      className={buttonStyle}
      onMouseEnter={handleSetHovered}
      onMouseLeave={handleSetUnhovered}
      onFocus={handleSetHovered}
      onBlur={handleSetUnhovered}
      onClick={onClick}
    >
      <Text
        color={isHovered ? 'red' : 'black'}
        align="center"
      >
        <BurgerIcon isOpened={isOpened} isHovered={isHovered} />
        &nbsp;
        {isOpened ? 'close menu' : 'menu'}
      </Text>
    </button>
  );
}
