import { routerPaths } from '../consts';
import { ImagePath } from '../strict-strings';

export function generateUrl(): string {
  return `${location.origin}${location.pathname}#${routerPaths.USER_CHOOSE_RESULTS}`;
}

export function generatePhotoUrl(photoPath: ImagePath): string {
  return `${location.origin}${photoPath.valueOf()}`;
}
