import { Link } from 'components/link';
import React from 'react';
import { ReactComponent as Question } from './assets/Question.svg';
import {
  copyrightBlockStyle,
  copyrightStyle,
  logoBlockStyle
} from './copyright.style';
import { Text } from '../text';

export function CopyRight(): JSX.Element {
  return (
    <div className={copyrightStyle}>
      <div className={copyrightBlockStyle}>
        <Text color="white" size="m" align="center">© syndicate {new Date().getFullYear()}</Text>
        <Link target="_blank" color="white" size="m" href="https://taragraphy.com/">
          site by TARAGRAPHY.COM
          <Question />
        </Link>
      </div>
      <div className={logoBlockStyle}>
        <Text color="black" size="l" align="center">Syndicate <br /> Architects</Text>
      </div>
    </div>
  );
}
