import { css } from '@emotion/css';

export const buttonStyle = css({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  background: 'none',
  border: 'none',
  margin: '0 auto'
});
