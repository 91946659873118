import React from 'react';
import { TTextProps } from './text.props-type';
import { textStyle } from './text.style';

export function Text({
  size = 'm',
  color = 'white',
  type = 'p',
  align = 'left',
  children,
  cursor,
  onClick,
  nohover
}: TTextProps): JSX.Element {
  return React.createElement(type,
    {
      className: textStyle(size, color, !!onClick, align, cursor, nohover),
      onClick
    },
    children
  );
}
