import React from 'react';
import { footerStyle, layoutStyle } from './layout.style';
import { headerMenuHeightPX } from '../consts';
import { CopyRight } from '../copyright';
import { EmptyBlock } from '../empty-block';
import { Colors } from '../enums';

type TLayoutProps = {
  children: React.ReactNode;
  bgrColor?: Colors;
  ignoreHeader?: boolean;
  withFooter?: boolean;
};

export function Layout({
  children,
  bgrColor,
  ignoreHeader = true,
  withFooter = false
}: TLayoutProps): JSX.Element {
  React.useEffect(() => {
    document.body.style.backgroundColor = bgrColor || Colors.BLACK;
  }, [bgrColor]);


  return (
    <>
      <main
        className={layoutStyle}
      >
        {!ignoreHeader && <EmptyBlock height={headerMenuHeightPX} />}
        {children}
      </main>
      {withFooter && (
        <footer className={footerStyle}>
          <CopyRight />
        </footer>
      )}
    </>
  );
}
