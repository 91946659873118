import {
  resultsPageService,
  TSelectionProjectItem,
  useNavigatorState,
  UserSwipe
} from 'common';
import {
  BottomMenu,
  ColumnLayout,
  Layout,
  ProjectItem
} from 'components';
import React from 'react';
import { EmptyResultPage } from './empty-result-page';
import { ResultSwitcher } from './result-switcher';
import { ResultsMenuSheet } from './results-menu-sheet';

type TResultsPageContentProps = {
  data: Record<UserSwipe, Array<TSelectionProjectItem>>;
  onClear: VoidFunction | undefined;
};

export function ResultsPageContent({ data, onClear }: TResultsPageContentProps): JSX.Element {
  const { view } = useNavigatorState({ view: UserSwipe.SELECT });
  const { selectedCount, rejectedCount } = resultsPageService.getResultsCount();
  const items = data[view];

  React.useEffect(() => resultsPageService.switchState(view), [view]);

  if (!items.length) {
    return <EmptyResultPage view={view} />;
  }

  return (
    <Layout>
      <ColumnLayout>
        {data[view].map((item: TSelectionProjectItem) => (
          <ProjectItem
            key={`${item.projectId}-${item.photo.valueOf()}`}
            id={item.projectId}
            projectName={item.projectName}
            imageUrl={item.photo}
          />
        ))}
      </ColumnLayout>
      <BottomMenu
        menuOpenedContent={
          <ResultsMenuSheet
            selectedCount={selectedCount}
            rejectedCount={rejectedCount}
            onClear={onClear}
            view={view}
          />
        }
        menuClosedContent={
          <ResultSwitcher
            selectedCount={selectedCount}
            rejectedCount={rejectedCount}
            view={view}
          />
        }
      />
    </Layout>
  );
}
