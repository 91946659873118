import { useOnClickOutside } from 'common';
import React from 'react';
import {
  bottomMenuStyle,
  openedMenuStyle
} from './bottom-menu.style';
import { Text } from '../text';

type TBottomMenuProps = {
  menuClosedContent: React.ReactNode;
  menuOpenedContent?: React.ReactNode;
};

export function BottomMenu({ menuClosedContent, menuOpenedContent }: TBottomMenuProps): JSX.Element {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const menuRef = React.useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = React.useState(0);
  const [isOpened, setIsOpened] = React.useState(false);

  const handleOpenMenu = React.useCallback(() => setIsOpened(true), [setIsOpened]);
  const handleCloseMenu = React.useCallback(() => setIsOpened(false), [setIsOpened]);

  useOnClickOutside(menuRef, handleCloseMenu);

  React.useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      const { height: realHeight } = current.getBoundingClientRect();
      setHeight(realHeight);
    }
  }, []);

  return (
    <div className={bottomMenuStyle(isOpened, height)} ref={menuRef}>
      {isOpened && <div className={openedMenuStyle} onClick={handleCloseMenu}>{menuOpenedContent}</div>}
      {isOpened
          ? <Text color="white" onClick={handleCloseMenu}>close</Text>
          : <div ref={ref} onClick={menuOpenedContent ? handleOpenMenu : void 0}>{menuClosedContent}</div>}
    </div>
  );
}
