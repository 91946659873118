import { css } from '@emotion/css';
import { Colors } from '../../../enums';

export const crossIconStyle = (isHovered: boolean): string => css({
  position: 'relative',
  top: '1px',

  ['& path']: {
    stroke: isHovered ? Colors.RED : Colors.BLACK
  }
});

export const burgerIconStyle = (isHovered: boolean): string => css({
  ['& path']: {
    fill: isHovered ? Colors.RED : Colors.BLACK
  }
});
