import { css } from '@emotion/css';

export const roundedButtonStyle = css({
  width: '66px',
  height: '66px',
  backgroundColor: '#fff',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'border-radius 150ms ease-in, transform 50ms ease-in',

  [':hover']: {
    transform: 'scale(1.05)'
  },
  [':active']: {
    transform: 'scale(1.05)'
  }
});
