import { css } from '@emotion/css';
import { Colors } from 'components';

export const projectInfoStyle = css({
  maxWidth: '845px',
  paddingRight: '30px'
});

export const projectPhotoStyle = css({
  width: '100%',
  height: 'auto',
  display: 'block',
  backgroundColor: Colors.WHITE
});
