import React from 'react';
import {
  buttonStyle,
  detailedStyle,
  iconFillStyle
} from './detailed.style';
import { Plus } from './plus';
import { Text } from '../text';

type TDetailedProps = {
  onOpenDetailedInfo: VoidFunction;
  projectName: string;
};

export function Detailled({ onOpenDetailedInfo, projectName }: TDetailedProps): JSX.Element {
  return (
    <div className={detailedStyle}>
      <Text color="black" align="center">
        {projectName}
      </Text>
      <div className={buttonStyle}>
        <Text color="black" align="center" onClick={onOpenDetailedInfo}>
          more info <Plus className={iconFillStyle} />
        </Text>
      </div>
    </div>
  );
}
