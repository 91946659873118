import { css } from '@emotion/css';

export const descriptionStyle = css({
  maxWidth: '845px'
});

export const contactsStyle = css({
  display: 'flex',
  flexDirection: 'column'
});
