import React from 'react';
import { ErrorPage } from './error-page';
import { LoaderPage } from './loader-page';

type TProcessingWrapperProps<T> = {
  data: T | void | boolean;
  error: Error | void | boolean;
  children: (value: T) => JSX.Element;
};

export function ProcessingWrapper<T>(props: TProcessingWrapperProps<T>): JSX.Element {
  if (!props.data && !props.error) return <LoaderPage />;
  if (!props.data && props.error) return <ErrorPage />;

  return props.children(props.data as T);
}
