import { routerHistory } from '../consts';

export class ScrollTopService {
  constructor() {
    routerHistory.listen(this.scrollTop);
  }

  scrollTop = (): void => {
    window.scrollTo(0, 0);
  };
}
