import { css } from '@emotion/css';
import { resetService } from 'common';
import React from 'react';
import { Layout, LayoutContent } from '../layout';
import { Text } from '../text';

const containerStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: '100%'
});

export function ErrorPage(): JSX.Element {
  return (
    <Layout>
      <LayoutContent height={window.innerHeight}>
        <div className={containerStyle}>
          <div>
            <Text color="red" size="l">Что-то пошло не так...</Text>
            <Text
              color="white"
              size="m"
              onClick={resetService.resetApplication}
            >
                Очистить кэш и перезагрузить страницу
            </Text>
          </div>
        </div>
      </LayoutContent>
    </Layout>
  );
}
