import {
  TAnalyticsDataRequest,
  TGetUserSendedSelectionResponse,
  TSetUserSelectionRequest,
  TSetUserSelectionResponse
} from './models';
import { ISyndicateTransport } from './transport.interface';
import { transport } from '../utils';

class SyndicateTransport implements ISyndicateTransport {
  private static ENDPOINT: string =
  'https://script.google.com/macros/s/AKfycbzEm8FN-RSzCr1jyU9AaPCvEn8koaQFo_v1wFWYkJBVpK3wWIuahRgG2bJqpnWg30iu/exec';

  getUserSendedSelection(selectedId: string, rejectedId: string): Promise<TGetUserSendedSelectionResponse> {
    return transport.post({
      url: SyndicateTransport.ENDPOINT,
      method: 'get_user_sended_selection',
      params: { selectedId, rejectedId }
    });
  }

  setUserSelection(data: TSetUserSelectionRequest): Promise<TSetUserSelectionResponse> {
    return transport.post({
      url: SyndicateTransport.ENDPOINT,
      method: 'set_user_selection',
      params: data
    });
  }

  sendAnalytics(data: TAnalyticsDataRequest): boolean {
    return transport.sendBeacon({
      url: SyndicateTransport.ENDPOINT,
      method: 'set_analytics',
      params: data
    });
  }

  sendError(
    fingerPrint: string,
    errorMessage: string,
    errorStack: string,
    componentStack: string
  ): Promise<void> {
    return transport.post({
      url: SyndicateTransport.ENDPOINT,
      method: 'send_error_info',
      params: { fingerPrint, errorMessage, errorStack, componentStack }
    });
  }
}

export const syndicateTransport = new SyndicateTransport();
