import React from 'react';
import { emptyBlockStyle } from './empty-block.style';

type TEmptyBlockProps = {
  height?: string | number;
  width?: string;
};

export function EmptyBlock({ height = '0px', width = '0px' }: TEmptyBlockProps): JSX.Element {
  return <div className={emptyBlockStyle(height, width)} />;
}
