import { analytics, ImagePath, usePlaceholderImg, UserSwipe } from 'common';
import {
  ButtonsContainer,
  RoundedButton
} from 'components';
import React from 'react';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { choosePageImageStyle } from './choose-page.style';

type TChoosePageImageProps = {
  imageUrl: ImagePath;
  children?: React.ReactNode;
  onSwipe?: (userSwipe: UserSwipe) => void;
};

const rotationCoefficient = 0.05;
const moveFromPageCoefficient = 1.5;
const velocityCoefficient = 0.5;
const defaultTransitionTimeSec = 0.15;
const defaultTransitionSwipeOverTimeSec = 0.5;
const oneSecMS = 1000;

const defaultButtonScale = 3;

function setElementTransformStyle(
  el: HTMLDivElement,
  transitionTimeSec: number,
  translateX: number,
  rotateDeg: number
): void {
  el.style.transition = `transform ${transitionTimeSec}s ease-out`;
  el.style.transform = `translateX(${translateX}px) rotate(${rotateDeg}deg)`;
}

function setButtonTransformStyle(
  button: HTMLDivElement,
  scale: number,
  transitionTimeSec: number,
  hide: boolean,
  sign: number
): void {
  const maxScale = Math.min(scale, defaultButtonScale);

  button.style.transition = `transform ${transitionTimeSec}s ease-out`;
  button.style.transformOrigin = `bottom ${sign === -1 ? 'left' : 'right'}`;

  if (hide) {
    button.style.opacity = '0';
    return;
  }

  button.style.opacity = '1';
  button.style.transform = `scale(${maxScale})`;
}

export function ChoosePageImage({ imageUrl, children, onSwipe }: TChoosePageImageProps): JSX.Element {
  const { currentSrc, pixelated } = usePlaceholderImg(imageUrl);
  const ref = React.useRef<HTMLDivElement | null>(null);
  const crossRef = React.useRef<HTMLDivElement | null>(null);
  const heartRef = React.useRef<HTMLDivElement | null>(null);

  const handleSwipe = React.useCallback((sign: number, transitionTime: number) => {
    if (ref.current && crossRef.current && heartRef.current) {
      const width = window.innerWidth;

      // двигаем карточку за пределы экрана
      setElementTransformStyle(
        ref.current,
        transitionTime,
        sign * width * moveFromPageCoefficient,
        sign * width * rotationCoefficient
      );

      setButtonTransformStyle(crossRef.current, defaultButtonScale, transitionTime, sign !== -1, sign);
      setButtonTransformStyle(heartRef.current, defaultButtonScale, transitionTime, sign === -1, sign);

      setTimeout(() => {
        // карточка сдвинулась за пределы экрана - вызываем метод
        onSwipe && onSwipe(sign === -1 ? UserSwipe.REJECT : UserSwipe.SELECT);

        if (sign === -1) {
          analytics.onDislikeChoose(imageUrl);
        } else {
          analytics.onLikeChoose(imageUrl);
        }

        // возвращаем карточку на место, но немного ждём, чтобы не было морганий
        setTimeout(() => {
          if (ref.current && crossRef.current && heartRef.current) {
            setElementTransformStyle(ref.current, 0, 0, 0);
            setButtonTransformStyle(crossRef.current, 1, 0, false, sign);
            setButtonTransformStyle(heartRef.current, 1, 0, false, sign);
          }
        }, oneSecMS * defaultTransitionSwipeOverTimeSec);
      }, transitionTime * oneSecMS);
    }
  }, [onSwipe, imageUrl]);

  const handlers = useSwipeable({
    onSwiped: (eventData: SwipeEventData) => {
      if (ref.current && crossRef.current && heartRef.current) {
        const width = window.innerWidth;
        const { absX, velocity } = eventData;
        const sign = Math.sign(eventData.deltaX);

        if (absX < width / 2) {
          setElementTransformStyle(ref.current, defaultTransitionTimeSec, 0, 0);
          setButtonTransformStyle(crossRef.current, 1, defaultTransitionTimeSec, false, sign);
          setButtonTransformStyle(heartRef.current, 1, defaultTransitionTimeSec, false, sign);
        } else {
          const transitionTimeSec = Math.min(1 / velocity * velocityCoefficient, defaultTransitionSwipeOverTimeSec);

          handleSwipe(sign, transitionTimeSec);
        }
      }
    },
    onSwiping: (eventData: SwipeEventData) => {
      if (ref.current && crossRef.current && heartRef.current) {
        const sign = Math.sign(eventData.deltaX);
        const buttonScale = Math.max(Math.abs(eventData.deltaX * rotationCoefficient), 1);

        setButtonTransformStyle(crossRef.current, buttonScale, 0, sign !== -1, sign);
        setButtonTransformStyle(heartRef.current, buttonScale, 0, sign === -1, sign);
        setElementTransformStyle(ref.current, 0, eventData.deltaX, eventData.deltaX * rotationCoefficient);
      }
    }
  });

  const refPassthrough = (el: HTMLDivElement | null): void => {
    handlers.ref(el);
    ref.current = el;
  };

  const handleSelectItem = React.useCallback(() => handleSwipe(1, defaultTransitionSwipeOverTimeSec), [handleSwipe]);

  const handleRejectItem = React.useCallback(() => handleSwipe(-1, defaultTransitionSwipeOverTimeSec), [handleSwipe]);

  return (
    <div
      {...handlers}
      ref={refPassthrough}
      className={choosePageImageStyle(currentSrc, pixelated)}
    >
      {children}
      <ButtonsContainer>
        <div ref={crossRef}>
          <RoundedButton type="cross" onClick={handleRejectItem} />
        </div>
        <div ref={heartRef}>
          <RoundedButton type="heart" onClick={handleSelectItem} />
        </div>
      </ButtonsContainer>
    </div>
  );
}
