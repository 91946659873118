import { errorLoggerService } from 'common';
import React from 'react';
import { ErrorPage } from './error-page';

type TErrorBoundaryState = {
  error: Error | null;
};

export class ErrorBoundary extends React.Component<React.PropsWithChildren<object>, TErrorBoundaryState> {
  readonly state = {
    error: null
  };

  static getDerivedStateFromError(error: Error): TErrorBoundaryState {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    errorLoggerService.log(error.message, error.stack || '', errorInfo.componentStack);
  }

  render(): React.ReactNode {
    if (this.state.error) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
