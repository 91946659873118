import {
  userFormService,
  useRx
} from 'common';
import {
  Colors,
  EmptyBlock,
  FormControlField,
  Input,
  Text
} from 'components';
import React from 'react';
import { sendingStateButton } from './user-form.style';

type TUserFormProps = {
  onBackToGame?: VoidFunction;
  bgrColor?: Colors;
};

export function UserForm({ onBackToGame, bgrColor }: TUserFormProps): JSX.Element {
  const { name, contact } = userFormService.form.fields;
  const isReqiestProcessing = useRx(userFormService.isRequestProcessing$);
  const isFormSended = useRx(userFormService.isFormSended$);

  const formError = useRx(userFormService.form.formError$);

  const isNameTouched = useRx(name.touched$);
  const isContactTouched = useRx(contact.touched$);
  const isFormTouched = isNameTouched || isContactTouched;

  const handleSubmitForm = React.useCallback(() => {
    if (!isReqiestProcessing) {
      name.touch();
      contact.touch();
      userFormService.sendUserForm();
    }
  }, [name, contact, isReqiestProcessing]);

  React.useEffect(() => () => userFormService.reset(), []);

  return (
    <div>
      <FormControlField control={name}>
        <Input placeholder="your name" bgrColor={bgrColor} />
      </FormControlField>
      <FormControlField control={contact}>
        <Input placeholder="your email or phone" bgrColor={bgrColor} />
      </FormControlField>
      <EmptyBlock height="1px" />
      {isFormSended ? (
        <>
          <Text size="l" color="red">great! we’ll reach you out very soon</Text>
          {onBackToGame && (
            <>
              <EmptyBlock height="40px" />
              <Text size="l" color="red" onClick={onBackToGame}>back to game</Text>
            </>
          )}
        </>
      )
      : (
        <>
          <Text
            size="l"
            color={bgrColor === Colors.RED ? 'black' : 'red'}
            onClick={isReqiestProcessing ? void 0 : handleSubmitForm}
          >
            {isReqiestProcessing ? <div className={sendingStateButton}>sending</div> : 'send'}
          </Text>
          {isFormTouched && formError && (
            <Text size="l" color={bgrColor === Colors.RED ? 'black' : 'red'}>{formError}</Text>
          )}
        </>
      )
      }
    </div>
  );
}
