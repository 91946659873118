import { BehaviorSubject } from 'rxjs';
import type { FingerPrintService } from './fingerprint.service';
import { UserDataForm } from './user-data.form';
import type { UserDecisionStorageService } from './user-decision-storage.service';
import { UserDecisionService } from './user-decision.service';
import { FormSendType } from '../enums';
import { ISyndicateTransport } from '../transport';
import { generateUrl } from '../utils';

export class UserFormService {
  form: UserDataForm;
  readonly isRequestProcessing$ = new BehaviorSubject<boolean>(false);
  readonly isFormSended$ = new BehaviorSubject<boolean>(false);

  constructor(
    private transport: ISyndicateTransport,
    private storage: UserDecisionStorageService,
    private fingerPrintService: FingerPrintService,
    private userDecisionService: UserDecisionService
  ) {
    this.form = new UserDataForm();
  }

  reset(): void {
    this.isRequestProcessing$.next(false);
    this.isFormSended$.next(false);
    this.form = new UserDataForm();
  }

  sendUserForm = (): void => {
    if (this.form.isFormValid$.getValue()) {
      this.isRequestProcessing$.next(true);

      void this.fingerPrintService.getFingerPrint().then((fingerPrint: string) => {
        const result = this.storage.getItems();
        const name = this.form.fields.name.value;
        const contact = this.form.fields.contact.value;

        const isEndGame = this.userDecisionService.data$.getValue().data?.length === 0 || false;

        void this.transport.setUserSelection({
          name,
          contact,
          result,
          fingerPrint,
          url: generateUrl(),
          type: isEndGame ? FormSendType.ENDGAME : FormSendType.CONTACT_US
        })
          .then(() => this.isFormSended$.next(true))
          .finally(() => this.isRequestProcessing$.next(false));
      });
    }
  };
}
