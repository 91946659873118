import { analytics, ImagePath, Navigator } from 'common';
import React from 'react';
import {
  projectDetailedStyle,
  projectImgStyle,
  projectItemStyle
} from './project-item.style';
import { Detailled } from '../detailed';
import { HoverWrapper } from '../hover-wrapper';
import { Img } from '../image';

type TProjectItemProps = {
  imageUrl: ImagePath;
  projectName: string;
  id: string;
};

export function ProjectItem({ imageUrl, projectName, id }: TProjectItemProps): JSX.Element {
  const handleOpenDetailedInfo = React.useCallback(() => {
    Navigator.navigateToProject(id);
    analytics.onProjectLabelClick(id);
  }, [id]);

  const handleHover = React.useCallback((state: 'enter' | 'leave') => {
    analytics.onProjectLabelToggle(id, state === 'enter' ? 'show' : 'hide');
  }, [id]);

  return (
    <div className={projectItemStyle}>
      <Img className={projectImgStyle} src={imageUrl} alt={projectName} />
      <div className={projectDetailedStyle}>
        <HoverWrapper onHover={handleHover}>
          <Detailled onOpenDetailedInfo={handleOpenDetailedInfo} projectName={projectName} />
        </HoverWrapper>
      </div>
    </div>
  );
}
