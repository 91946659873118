import {
  BehaviorSubject,
  combineLatest,
  Subscription
} from 'rxjs';
import { FormControl } from '../utils';

enum FormControlErrors {
  EMPTY_FIELD = 'Введите значение',
  EMPTY = ''
}

enum FormErrors {
  EMPTY_CONTROL = 'Please fill these two forms'
}

export class UserDataForm {
  readonly isFormValid$ = new BehaviorSubject<boolean>(false);
  readonly formError$ = new BehaviorSubject<string>('');

  readonly fields = {
    name: new FormControl<string, FormControlErrors>('', UserDataForm.isRequired),
    contact: new FormControl<string, FormControlErrors>('', UserDataForm.isRequired)
  };

  constructor() {
    this.validateForm();
  }

  private static isRequired = (value: string): FormControlErrors =>
  value.trim() ? FormControlErrors.EMPTY : FormControlErrors.EMPTY_FIELD;

  private validateForm(): Subscription | void {
    const name = this.fields.name;
    const contact = this.fields.contact;

    return combineLatest(
      name.error$, contact.error$
    ).subscribe(([nameError, contactError]: [FormControlErrors, FormControlErrors]) => {
      this.isFormValid$.next(!nameError && !contactError);
      this.formError$.next(this.resolveFormError([nameError, contactError]));
    });
  }

  private resolveFormError([nameError, contactError]: [FormControlErrors, FormControlErrors]): string {
    if (nameError || contactError) {
      return FormErrors.EMPTY_CONTROL;
    }

    return '';
  }
}
