import React from 'react';
import { desktopHowerWrapperStyle } from './hover-wrapper.style';
import { THowerWrapperProps } from './prop.type';

export function HoverWrapperDesktop({ children }: THowerWrapperProps): JSX.Element {
  return (
    <div className={desktopHowerWrapperStyle}>
      {children}
    </div>
  );
}
