import { TProjectModel } from 'projects';
import { BehaviorSubject } from 'rxjs';
import type { ImageLoaderService } from './image-loader.service';
import type { StaticProjectsStorage } from './static-projects.storage';
import { TProjects } from '../types';

export type TAllProjectsServiceData = {
  data: TProjects | void;
  error: Error | void;
};

export class AllProjectsService {
  private cache: TProjects = [];
  private static initialData: TAllProjectsServiceData = { data: void 0, error: void 0 };
  private static resetHashtag = '#ALLPROJECTS';

  readonly data$ = new BehaviorSubject<TAllProjectsServiceData>(AllProjectsService.initialData);
  readonly hashtags$ = new BehaviorSubject<Array<string>>([]);
  readonly currentHashTag$ = new BehaviorSubject<string>(AllProjectsService.resetHashtag);

  constructor(private staticProjectsStorage: StaticProjectsStorage, private imageLoaderService: ImageLoaderService) {
    this.currentHashTag$.subscribe(this.filterProjectsByHashtag);
  }

  getProjects(): void {
    const projects = this.staticProjectsStorage.projects$.getValue()
      .filter(({ include }: TProjectModel) => include)
      .sort((aPr: TProjectModel, bPr: TProjectModel) => aPr.weight - bPr.weight);

    const covers = projects.map((project: TProjectModel) => project.cover);

    void this.imageLoaderService.fetchImages(covers);

    const tags = Array.from(new Set(projects.map(({ hashtags }: TProjectModel) => hashtags).flat()));

    this.cache = projects;
    this.hashtags$.next([AllProjectsService.resetHashtag, ...tags]);
    this.data$.next({ data: projects, error: void 0 });
  }

  setHashtag = (hashtag: string): void => this.currentHashTag$.next(hashtag);

  private filterProjectsByHashtag = (hashtag: string): void => {
    if (hashtag === AllProjectsService.resetHashtag) {
      this.data$.next({ data: this.cache, error: void 0 });
      return;
    }

    const data = this.cache.filter((project: TProjectModel) => project.hashtags.includes(hashtag));

    this.data$.next({ data, error: void 0 });
  };
}
