import { introService, useRx } from 'common';
import React from 'react';
import { IntroPage } from './intro-page';

export function IntroPageWrapper({ children }: React.PropsWithChildren<object>): JSX.Element {
  const isIntroViewed = useRx(introService.introViewed$);

  if (isIntroViewed) return <>{children}</>;

  return <IntroPage onSetIntroViewed={introService.setIntroViewed}>{children}</IntroPage>;
}
