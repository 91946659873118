import { LineHeightPx } from 'components/enums';
import React from 'react';
import {
  buttonWrapper,
  maxLineCount,
  textFolderStyle,
  textWrapperStyle
} from './text-folder.style';
import { Text } from '../text';
import { TFontSize } from '../types';

type TTextFolderProps = {
  children: React.ReactNode;
  buttonColor: 'red' | 'white';
  fontSize: TFontSize;
};

export function TextFolder({ children, buttonColor, fontSize }: TTextFolderProps): JSX.Element {
  const [isFolded, setIsFolded] = React.useState(true);
  const ref = React.useRef<HTMLDivElement | null>(null);

  const setIsUnfolded = React.useCallback(() => setIsFolded(false), []);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      const { current } = ref;

      if (current) {
        const lineHeight = fontSize === 'l' ? LineHeightPx.L : LineHeightPx.M;
        const { height } = current.getBoundingClientRect();
        const lineCount = Math.trunc(height / lineHeight);

        setIsFolded(lineCount > maxLineCount);
      }
    });
  }, [fontSize]);

  return (
    <div>
      <div className={textFolderStyle(isFolded, fontSize)}>
        <div ref={ref} className={textWrapperStyle}>
          {children}
        </div>
      </div>
      <div className={buttonWrapper(isFolded)}>
        <Text size="l" color={buttonColor} onClick={setIsUnfolded}>more</Text>
      </div>
    </div>
  );
}
