import { css } from '@emotion/css';
import { generalMenuWidthPX } from '../consts';

export const buttonsContainerWrapperStyle = css({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  display: 'flex',
  zIndex: 6,
  justifyContent: 'center'
});

export const buttonsContainerStyle = css({
  display: 'flex',
  width: `${generalMenuWidthPX}px`,
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  padding: '0 30px 30px'
});
