import { AllProjectsService } from './all-projects.service';
import { AnalyticsService } from './analytics.service';
import { ErrorLoggerService } from './error-logger.service';
import { FingerPrintService } from './fingerprint.service';
import { ImageLoaderService } from './image-loader.service';
import { ImageStorage } from './image.storage';
import { ImgCacheService } from './img-cache.service';
import { IntroService } from './intro.service';
import { ProjectService } from './project.service';
import { ResetService } from './reset-service';
import { ResultsPageService } from './results-page.service';
import { ScrollTopService } from './scroll-top.service';
import { StaticProjectsStorage } from './static-projects.storage';
import { UserDecisionStorageService } from './user-decision-storage.service';
import { UserDecisionService } from './user-decision.service';
import { UserFormService } from './user-form.service';

import { syndicateTransport } from '../transport';

export * from './navigator.service';

const fingerprintService = new FingerPrintService();
const imageCacheService = new ImgCacheService();

export const userDecisionStorageService = new UserDecisionStorageService();
export const imageStorage = new ImageStorage();

export const imageLoaderService = new ImageLoaderService(imageCacheService);

export const staticProjectsStorage = new StaticProjectsStorage(imageStorage);
export const userDecisionService = new UserDecisionService(userDecisionStorageService, staticProjectsStorage, imageLoaderService);
export const resultsPageService = new ResultsPageService(
  syndicateTransport,
  userDecisionStorageService,
  staticProjectsStorage,
  imageLoaderService
);
export const allProjectsService = new AllProjectsService(staticProjectsStorage, imageLoaderService);
export const projectService = new ProjectService(staticProjectsStorage, imageLoaderService);
export const userFormService = new UserFormService(
  syndicateTransport,
  userDecisionStorageService,
  fingerprintService,
  userDecisionService
);
export const errorLoggerService = new ErrorLoggerService(syndicateTransport, fingerprintService);
export const introService = new IntroService();
export const scrollTopService = new ScrollTopService();
export const analytics = new AnalyticsService(syndicateTransport, fingerprintService);

export const resetService = new ResetService(userDecisionStorageService);
