export class StorageService<T extends unknown> {
  private storage = window.localStorage;
  private state: T;

  constructor(private storageKey: string, defaultData: T) {
    const oldData = this.getFromStorage();
    this.state = oldData ? oldData : defaultData;
  }

  static makeInstance<T extends unknown>(storageKey: string, defaultData: T): StorageService<T> {
    return new StorageService<T>(storageKey, defaultData);
  }

  getState(): T {
    return this.state;
  }

  setState(data: T): void {
    this.setToStorage(data);
    this.state = data;
  }

  private getFromStorage(): T | null {
    const rawData = this.storage.getItem(this.storageKey);
    if (!rawData) return null;

    return JSON.parse(rawData) as T;
  }

  private setToStorage(data: T): void {
    try {
      const serialized = JSON.stringify(data);
      this.storage.setItem(this.storageKey, serialized);
    } catch (e) {
      throw new Error('The store state is not serializable');
    }
  }
}
