import { TProjectModel } from 'projects';
import { BehaviorSubject } from 'rxjs';
import type { ImageLoaderService } from './image-loader.service';
import { Navigator } from './navigator.service';
import type { StaticProjectsStorage } from './static-projects.storage';
import type { UserDecisionStorageService } from './user-decision-storage.service';
import { UserSwipe } from '../enums';
import { ImagePath } from '../strict-strings';
import { TItems, TSelectionProjectItem, TUserChoiseItem } from '../types';
import { randomizeArray } from '../utils';

export type TUserDecisionServiceData = {
  data: TItems | void;
  error: Error | void;
};

const SHOW_ITS_MATCH_PAGE_EVERY_COUNT = 5;

export class UserDecisionService {
  private selectedCount: number = 0;
  private static initialData: TUserDecisionServiceData = { data: void 0, error: void 0 };

  data$ = new BehaviorSubject<TUserDecisionServiceData>(UserDecisionService.initialData);

  constructor(
    private storage: UserDecisionStorageService,
    private staticProjectsStorage: StaticProjectsStorage,
    private imageLoaderService: ImageLoaderService
  ) {}

  getItems(): void {
    if (this.data$.getValue().data) {
      return;
    }

    const projects: Array<TSelectionProjectItem> = this.staticProjectsStorage.projects$.getValue()
      .filter(({ include }: TProjectModel) => include)
      .map((project: TProjectModel) => {
        const selection = project.selection
          .map((photo: ImagePath) =>
            ({ photo, projectId: project.id, projectName: project.name }));

        return selection;
      })
      .flat()
      .sort(randomizeArray)
      .sort(randomizeArray);

    const data = this.filterAlreadyChoosedItems(projects);
    this.setSelectedItemsCount();

    if (!data.length) {
      this.data$.next({ data, error: void 0 });
      return;
    }

    const images = data.map((item: TSelectionProjectItem) => item.photo);

    void this.imageLoaderService.fetchImage(images[0]).finally(() => {
      this.data$.next({ data, error: void 0 });
      void this.imageLoaderService.fetchImages(images, 1);
    }
    );
  }

  setItem(item: TSelectionProjectItem, decision: UserSwipe): void {
    this.storage.setItem(item.photo, decision);

    const { data } = this.data$.getValue();

    this.decideToOpenItsMatchPage(decision);

    if (data) {
      const filtered = data.filter((dataItem: TSelectionProjectItem) => dataItem.photo !== item.photo);
      this.data$.next({ data: filtered, error: void 0 });
    }
  }

  reset(): void {
    this.data$.next(UserDecisionService.initialData);
    this.selectedCount = 0;
  }

  private filterAlreadyChoosedItems(itemsFromResponse: Array<TSelectionProjectItem>): TItems {
    const swipedItems = this.storage.getItems();

    if (!swipedItems.length) {
      return itemsFromResponse;
    }

    return itemsFromResponse.filter((itemFromResponse: TSelectionProjectItem) =>
      swipedItems.every((swipedItem: TUserChoiseItem) =>
        !itemFromResponse.photo.includes(swipedItem.photo.valueOf())
      ));
  }

  private setSelectedItemsCount(): void {
    const swipedItems = this.storage.getItems();

    this.selectedCount = swipedItems.filter(({ decision }: TUserChoiseItem) => decision === UserSwipe.SELECT).length;
  }

  private decideToOpenItsMatchPage(decision: UserSwipe): void {
    if (decision === UserSwipe.SELECT) {
      this.selectedCount = this.selectedCount + 1;
    }

    if (
      decision === UserSwipe.SELECT
      && this.selectedCount > 0
      && this.selectedCount % SHOW_ITS_MATCH_PAGE_EVERY_COUNT === 0
    ) {
      Navigator.navigateToItsMatch();
    }
  }
}
