import {
  analytics,
  Navigator,
  resultsPageService,
  useOnClickOutside,
  useZIndex
} from 'common';
import React from 'react';
import { MenuButton } from './components';
import {
  headerMenuStyle,
  menuItemsStyle
} from './header.style';
import { ReactComponent as Statistics } from './Statistics.svg';
import { EmptyBlock } from '../empty-block';
import { Text } from '../text';

export function Header(): JSX.Element {
  const [isMenuOpened, setIsMenuOpened] = React.useState(false);

  const ref = React.useRef<HTMLDivElement | null>(null);
  const zIndex = useZIndex(isMenuOpened);

  const handleToggleMenu = React.useCallback(() => setIsMenuOpened((isMenuOpenedPrev: boolean) => {
    !isMenuOpenedPrev && analytics.onMenuOpen();

    return !isMenuOpenedPrev;
  }), []);

  useOnClickOutside(ref, () => setIsMenuOpened(false));

  return (
    <header
      className={headerMenuStyle(isMenuOpened)}
      style={{ zIndex }}
      ref={ref}
    >
      <div>
        <Text
          color="black"
          size="l"
          align="center"
          onClick={Navigator.navigateToGame}
          nohover
        >
            Syndicate<br />Architects
        </Text>
        <EmptyBlock height="14px" />
        <MenuButton isOpened={isMenuOpened} onClick={handleToggleMenu} />
      </div>
      {isMenuOpened
      && (() => {
        const { selectedCount, rejectedCount } = resultsPageService.getResultsCount();

        return (
          <>
            <div className={menuItemsStyle} onClick={handleToggleMenu}>
              <Text
                size="l"
                color="black"
                align="center"
                onClick={Navigator.navigateToStudio}
              >
                about us
              </Text>
              <Text
                size="l"
                color="black"
                align="center"
                onClick={Navigator.navigateToAllProjects}
              >
                all projects
              </Text>
              <Text
                size="l"
                color="black"
                align="center"
                onClick={Navigator.navigateToContactForm}
              >
                contact
              </Text>
            </div>
            <div className={menuItemsStyle} onClick={handleToggleMenu}>
              <Text
                size="m"
                color="black"
                align="center"
              >
                your
                &nbsp;
                <Statistics />
                &nbsp;
                statistics
              </Text>
              <EmptyBlock height="14px" />
              <Text
                size="l"
                color="black"
                align="center"
                onClick={Navigator.navigateToLikes}
              >
                likes {selectedCount}
              </Text>
              <Text
                size="l"
                color="black"
                align="center"
                onClick={Navigator.navigateToSecretDesires}
              >
                dislikes {rejectedCount}
              </Text>
              <Text
                size="l"
                color="red"
                align="center"
                onClick={Navigator.navigateToGame}
                nohover
              >
                back to game
              </Text>
            </div>
          </>);
      })()}
    </header>
  );
}
