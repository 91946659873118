import type { FingerPrintService } from './fingerprint.service';
import { ISyndicateTransport } from '../transport';

export class ErrorLoggerService {
  constructor(
    private transport: ISyndicateTransport,
    private fingerPrintService: FingerPrintService
  ) {}

  log(errorMessage: string, errorStack: string, componentStack: string): void {
    void this.fingerPrintService.getFingerPrint()
      .then((fingerPrint: string) =>
        this.transport.sendError(fingerPrint, errorMessage, errorStack, componentStack));
  }
}
