import { routerHistory } from '../consts';

export function useSearchParams(keys: Array<string>): Record<string, string | null> {
  const searchParams = new URLSearchParams(routerHistory.location.search);
  const result: Record<string, string | null> = {};

  keys.forEach((key: string) => {
    result[key] = searchParams.get(key);
  });

  return result;
}
