import {
  Navigator,
  UserSwipe
} from 'common';
import {
  EmptyBlock,
  Text
} from 'components';
import React from 'react';
import { PageWithForm } from '../page-with-form';

type TEmptyResultPageProps = {
  view: UserSwipe;
};

const textViewMap: Record<UserSwipe, JSX.Element> = {
  [UserSwipe.REJECT]: (
    <>
      <Text size="l">
        THAT&rsquo;S PRETTY WEIRD, BUT IT&nbsp;SEEMS YOU HAVEN&rsquo;T DISLIKED ANY IMAGE YET.
      </Text>
    </>
  ),
  [UserSwipe.SELECT]: (
    <>
      <Text size="l">
        well, it&nbsp;hurts a&nbsp;bit, but we&rsquo;re fine with the fact that you haven&rsquo;t liked any image yet.
      </Text>
    </>
  )
};

export function EmptyResultPage({ view }: TEmptyResultPageProps): JSX.Element {
  return (
    <PageWithForm>
      {textViewMap[view]}
      <Text size="l">
        WANT TO&nbsp;GET BACK AND GIVE IT&nbsp;A&nbsp;SHOT?
      </Text>
      <EmptyBlock height="40px" />
      <Text size="l" type="span">
        <Text
          size="l"
          type="span"
          color="red"
          onClick={Navigator.navigateToGame}
        >
          Get back to game&nbsp;
        </Text>
        or contact us:
      </Text>
    </PageWithForm>
  );
}
