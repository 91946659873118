import { Colors } from 'components/enums';
import React from 'react';
import { inputStyle } from './input.style';
import { TFormControlChildrenProps } from '../form-control-field';

type TInputProps = {
  placeholder: string;
  bgrColor?: Colors;
} & TFormControlChildrenProps<string>;

export function Input({ onChange, value, placeholder, onLeave, bgrColor }: TInputProps): JSX.Element {
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(event.target.value), [onChange]
  );

  return (
    <input
      className={inputStyle(bgrColor)}
      onChange={handleChange}
      placeholder={placeholder}
      value={value}
      onBlur={onLeave}
    />
  );
}
