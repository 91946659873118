import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

export function useRx<T>(stream: BehaviorSubject<T>): T {
  const [value, setValue] = useState<T>((): T => stream.getValue());

  useEffect(() => {
    const subscription = stream.subscribe((nextValue: T) => setValue(nextValue));

    return () => subscription.unsubscribe();
  }, [stream]);

  return value;
}
