import { StorageService } from './storage.service';
import type { UserSwipe } from '../enums';
import { ImagePath } from '../strict-strings';
import { TUserChoiseData } from '../types';
import { getImageNameFromPath } from '../utils';


export class UserDecisionStorageService {
  private storage = StorageService.makeInstance<TUserChoiseData>('userdecision', []);

  setItem(photoPath: ImagePath, decision: UserSwipe): void {
    const data = this.storage.getState();
    const photoFileName = getImageNameFromPath(photoPath);
    const itemToSave = { photo: photoFileName, decision };

    data.push(itemToSave);

    this.storage.setState(data);
  }

  getItems(): TUserChoiseData {
    return this.storage.getState();
  }

  clear(): void {
    this.storage.setState([]);
  }
}
