import { analytics } from 'common';
import {
  Layout,
  Colors,
  Text,
  Link,
  EmptyBlock,
  LayoutContent,
  ContentPageLayout,
  TextFolder
} from 'components';
import React from 'react';
import {
  contactsStyle,
  descriptionStyle
} from './studio-page.style';

export function StudioPage(): JSX.Element {
  React.useEffect(() => {
    analytics.onViewPage('about us');
  }, []);

  return (
    <Layout
      bgrColor={Colors.RED}
      ignoreHeader={false}
      withFooter
    >
      <LayoutContent>
        <ContentPageLayout>
          <div className={descriptionStyle}>
            <Text size="l" color="black">Studio</Text>
            <EmptyBlock height="40px"/>
            <TextFolder buttonColor="white" fontSize="l">
              <Text size="l" color="black">
                Syndicate is&nbsp;an&nbsp;architectural practice started on&nbsp;a&nbsp;self-commissioned
                base in&nbsp;2017. We&nbsp;are working in&nbsp;all scales from furniture design to&nbsp;city
                masterplans, research and advisory in&nbsp;topics concerning built/physical environment.
                <br/>
                <br/>
                We&nbsp;are not creating paper architecture&nbsp;&mdash; on&nbsp;the contrary&nbsp;&mdash; each
                project has a&nbsp;client and is&nbsp;meant to&nbsp;be&nbsp;built. However, we&nbsp;are our own
                client in&nbsp;everything we&nbsp;do. Being self-commissioned doesn&rsquo;t mean not taking
                it&nbsp;seriously, it&nbsp;just allows for a&nbsp;certain playfulness.
                <br />
                <br />
                Every project has a&nbsp;narrative which can be&nbsp;read by&nbsp;carefully looking at&nbsp;the
                design, sometimes you have to&nbsp;read between the lines. Hence, we&nbsp;consciously abstain from
                lengthy descriptions and do&nbsp;not ask you to&nbsp;understand what you see because what is&nbsp;done
                is&nbsp;not always under control, even for&nbsp;us.
                <br />
                <br />
                We&nbsp;always know exactly what we&nbsp;are doing but the results never cease to&nbsp;surprise.
              </Text>
            </TextFolder>
            <EmptyBlock height="30px"/>
          </div>
          <div className={contactsStyle}>
            <Link href="mailto:info@syndicate.archi" size="l">Contact us</Link>
            <Link href="https://www.instagram.com/syndicate_archi" target="_blank" rel="noopener norefer">
              Instagram
            </Link>
            <Link href="https://www.facebook.com/syndicatearchi/" target="_blank" rel="noopener norefer">
              Facebook
            </Link>
          </div>
        </ContentPageLayout>
      </LayoutContent>
    </Layout>
  );
}
