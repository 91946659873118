import React from 'react';
import { contentPageLayoutStyle } from './content-page-layout.style';

type TContentPageLayoutProps = {
  children: React.ReactNode;
};

export function ContentPageLayout({ children }: TContentPageLayoutProps): JSX.Element {
  return (
    <div className={contentPageLayoutStyle(React.Children.count(children))}>
      {React.Children.map(children, (child: React.ReactNode, index: number) => <div key={index}>{child}</div>)}
    </div>
  );
}
