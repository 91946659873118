/**
 * Явно указываем, что используется путь до картинки
 */
export class ImagePath extends String {
  constructor(imagePath: string) {
    super(imagePath);
  }
}

/**
 * Явно указываем, что используется только имя файла картинки вида PROJECTID_IMGTYPE_INDEX.EXT
 */
export class ImageFileName extends String {
  constructor(imageFileName: string) {
    super(imageFileName);
  }
}

/**
 * Тип для base64 картинки-плейсхолдера, что хранится в памяти и показывается, пока грузится оригинал
 */
export class Base64ImagePlaceholder extends String {
  constructor(base64ImagePlaceholder: string) {
    super(base64ImagePlaceholder);
  }
}
