import { ImageType } from '../enums';
import { ImageFileName } from '../strict-strings';

export type TExtracIImageFileNameResult = {
  type: ImageType;
  projectId: string;
  index: number;
};

export function extracIImageFileName(imageFileNameWithExt: ImageFileName): TExtracIImageFileNameResult {
  const [imageFileName] = imageFileNameWithExt.split('.');
  const [projectId, type, index] = imageFileName.split('_');

  return {
    type: type as ImageType,
    index: Number(index),
    projectId
  };
}
