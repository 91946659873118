import React from 'react';
import { mobileLayoutStyle } from './column-layout.style';
import { TColumnLayoutProps } from './props.type';
import { EmptyBlock } from '../empty-block';

export function ColumnLayoutMobile({ children }: TColumnLayoutProps): JSX.Element {
  return (
    <div className={mobileLayoutStyle}>
      <EmptyBlock height="60px" />
      {children}
    </div>
  );
}
