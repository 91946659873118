import { TProjectModel } from 'projects';
import { BehaviorSubject } from 'rxjs';
import type { ImageLoaderService } from './image-loader.service';
import type { StaticProjectsStorage } from './static-projects.storage';

type TProjectServiceData = {
  data: TProjectModel | void;
  error: Error | void;
};

export class ProjectService {
  private static initialData: TProjectServiceData = { data: void 0, error: void 0 };

  readonly data$ = new BehaviorSubject<TProjectServiceData>(ProjectService.initialData);

  constructor(private staticProjectsStorage: StaticProjectsStorage, private imageLoaderService: ImageLoaderService) {}

  getProjectInfo(id: string): void {
    const project: TProjectModel = this.staticProjectsStorage.getProjectById(id);

    void this.imageLoaderService.fetchImages(project.photos);

    this.data$.next({ data: project, error: void 0 });
  }

  reset(): void {
    this.data$.next(ProjectService.initialData);
  }
}

