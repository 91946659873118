import { css } from '@emotion/css';
import { mobileMediaQuery } from '../consts';

export const layoutStyle = css({
  width: '100%',
  boxSizing: 'border-box'
});

export const footerStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
});

export const layoutContentStyle = (height?: string | number): string => css({
  width: '100%',
  height,
  boxSizing: 'border-box',
  padding: '0 40px',

  [mobileMediaQuery]: {
    padding: '0 20px'
  }
});
