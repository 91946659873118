import { useZIndex } from 'common';
import {
  ButtonsContainer,
  EmptyBlock,
  RoundedButton,
  Text
} from 'components';
import React from 'react';
import {
  buttonsWrapperStyle,
  introButtonStyle,
  introHeaderStyle,
  introPageContentStyle,
  introPageNoClickBlock,
  introPageStyle,
  textFirstParagraphStyle,
  textSecondParagraphStyle,
  textStyle
} from './intro-page.style';
import { TIntroPageProps } from './prop.type';

export function IntroPage({ onSetIntroViewed, children }: React.PropsWithChildren<TIntroPageProps>): JSX.Element {
  const zIndex = useZIndex();

  return (
    <>
      <div className={introPageNoClickBlock} />
      <div className={introPageStyle} style={{ zIndex }}>
        <div className={introPageContentStyle}>
          <div className={introHeaderStyle}>
            <Text color="black" size="l" align="center">
              Syndicate<br />Architects
            </Text>
          </div>
          <div className={textStyle}>
            <div className={textFirstParagraphStyle}>
              <Text color="white" size="m" align="center">
                discover a&nbsp;variety of&nbsp;shots from our signature projects and make your decision.
              </Text>
            </div>
            <br />
            <div className={textSecondParagraphStyle}>
              <Text color="white" size="m" align="center">
                swipe right or&nbsp;tap &lsquo;heart&rsquo; button to&nbsp;like an&nbsp;image,
                tap &lsquo;cross&rsquo; or&nbsp;swipe left to&nbsp;pass.
              </Text>
            </div>
            <EmptyBlock height="35px" />
            <button onClick={onSetIntroViewed} className={introButtonStyle}>
              <Text color="black" size="m" align="center">play</Text>
            </button>
          </div>
          <div className={textSecondParagraphStyle}>
            <div className={buttonsWrapperStyle}>
              <ButtonsContainer>
                <RoundedButton type="cross" />
                <RoundedButton type="heart" />
              </ButtonsContainer>
            </div>
          </div>
        </div>
      </div>
      {children}
    </>
  );
}
