export const projectId = 'projectId';

const reactRouterReplacing = `:${projectId}`;

export const routerPaths = {
  MAIN: '/',
  USER_CHOOSE_RESULTS: '/results',
  STUDIO: '/studio',
  PROJECTS: '/projects',
  PROJECT: `/project/${reactRouterReplacing}`,
  CONTACT_US: '/contact-us',
  ITS_A_MATCH: '/its-a-match'
};

export function getProjectUrl(id: string): string {
  return routerPaths.PROJECT.replace(reactRouterReplacing, id);
}
