import 'intersection-observer';
import 'sendbeacon-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { SyndicatePage } from './syndicate-page';
import { resetService } from 'common';

resetService.init();

ReactDOM.render(
  <React.StrictMode>
    <SyndicatePage />
  </React.StrictMode>,
  document.getElementById('root')
);

