import React from 'react';
import { ReactComponent as Cross } from './assets/cross.svg';
import { ReactComponent as Heart } from './assets/heart.svg';
import { roundedButtonStyle } from './rounded-button.style';

type TRoundedButtonProps = {
  onClick?: VoidFunction;
  type: 'cross' | 'heart';
};

export function RoundedButton({ onClick, type }: TRoundedButtonProps): JSX.Element {
  return (
    <button onClick={onClick} className={roundedButtonStyle}>
      {type === 'cross' && <Cross />}
      {type === 'heart' && <Heart />}
    </button>
  );
}
