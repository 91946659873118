function checkIsMobile(): boolean {
  const { userAgent } = window.navigator;

  const isAndroid = (): boolean => Boolean(/Android/i.exec(userAgent));
  const isIos = (): boolean => Boolean(/iPhone|iPad|iPod/i.exec(userAgent));
  const isOpera = (): boolean => Boolean(/Opera Mini/i.exec(userAgent));
  const isWindows = (): boolean => Boolean(/IEMobile/i.exec(userAgent));

  return Boolean(isAndroid() || isIos() || isOpera() || isWindows());
}

export function useMobile(): boolean {
  return checkIsMobile();
}
