import { BehaviorSubject } from 'rxjs';
import {
  getProjectUrl,
  routerHistory,
  routerPaths
} from '../consts';
import { UserSwipe } from '../enums';

export type TResultsPageRouterState = {
  view: UserSwipe;
};

export class Navigator {
  static _state = new BehaviorSubject<unknown>(void 0);

  static navigateToStudio(): void {
    Navigator.navigate(routerPaths.STUDIO);
  }

  static navigateToAllProjects(): void {
    Navigator.navigate(routerPaths.PROJECTS);
  }

  static navigateToLikes(): void {
    Navigator.setState({ view: UserSwipe.SELECT });
    Navigator.navigate(routerPaths.USER_CHOOSE_RESULTS);
  }

  static navigateToSecretDesires(): void {
    Navigator.setState({ view: UserSwipe.REJECT });
    Navigator.navigate(routerPaths.USER_CHOOSE_RESULTS);
  }

  static navigateToGame(): void {
    Navigator.navigate(routerPaths.MAIN);
  }

  static navigateToItsMatch(): void {
    Navigator.navigate(routerPaths.ITS_A_MATCH);
  }

  static navigateToProject(projectId: string): void {
    Navigator.navigate(getProjectUrl(projectId));
  }

  static navigateToContactForm(): void {
    Navigator.navigate(routerPaths.CONTACT_US);
  }

  private static navigate(path: string): void {
    routerHistory.push(path);
  }

  private static setState(state: unknown): void {
    Navigator._state.next(state);
  }
}

