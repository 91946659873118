import { useMobile } from 'common';
import React from 'react';
import { HoverWrapperMobile } from './hover-wrapper-mobile';
import { HoverWrapperDesktop } from './hower-wrapper-desktop';
import { THowerWrapperProps } from './prop.type';

export function HoverWrapper({ children, onHover }: THowerWrapperProps): JSX.Element {
  const isMobile = useMobile();

  if (isMobile) return <HoverWrapperMobile onHover={onHover}>{children}</HoverWrapperMobile>;

  return <HoverWrapperDesktop>{children}</HoverWrapperDesktop>;
}
