import {
  Navigator,
  TSelectionProjectItem,
  TItems,
  UserSwipe,
  useMobile,
  analytics
} from 'common';
import {
  // BottomMenu,
  Detailled,
  HoverWrapper,
  Layout
//  Text
} from 'components';
import React from 'react';
import { ChoosePageImage } from './choose-page-image';
import {
  choosePageStyle,
  detailedWrapperInfo
} from './choose-page.style';

type TChoosePageContentProps = {
  itemsQueue: TItems;
  onSelectItem: (item: TSelectionProjectItem, decision: UserSwipe) => void;
};

export function ChoosePageContent({ itemsQueue, onSelectItem }: TChoosePageContentProps): JSX.Element {
  // const [isAtLeastItemSwiped, setAtLeastItemSwiped] = React.useState(false);
  const isMobile = useMobile();
  const firstItemInQueue = itemsQueue[0];
  const nextItemInQueue: TSelectionProjectItem | undefined = itemsQueue[1];

  const handleSwipe = React.useCallback((userSwipe: UserSwipe) => {
    //  setAtLeastItemSwiped(true);
    userSwipe === UserSwipe.REJECT && onSelectItem(firstItemInQueue, UserSwipe.REJECT);
    userSwipe === UserSwipe.SELECT && onSelectItem(firstItemInQueue, UserSwipe.SELECT);
  }, [firstItemInQueue, onSelectItem]);

  const handleOpenDetailedInfo = React.useCallback(() => {
    Navigator.navigateToProject(firstItemInQueue.projectId);
    analytics.onProjectLabelClick(firstItemInQueue.projectId);
  }, [firstItemInQueue]);

  const handleHover = React.useCallback((state: 'enter' | 'leave') => {
    analytics.onProjectLabelToggle(firstItemInQueue.projectId, state === 'enter' ? 'show' : 'hide');
  }, [firstItemInQueue.projectId]);

  React.useEffect(() => {
    if (isMobile) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobile]);

  return (
    <Layout>
      <div className={choosePageStyle}>
        {nextItemInQueue && <ChoosePageImage imageUrl={nextItemInQueue.photo} />}
        <ChoosePageImage
          key={firstItemInQueue.photo.valueOf()}
          imageUrl={firstItemInQueue.photo}
          onSwipe={handleSwipe}
        >
          <div className={detailedWrapperInfo}>
            <HoverWrapper onHover={handleHover}>
              <Detailled onOpenDetailedInfo={handleOpenDetailedInfo} projectName={firstItemInQueue.projectName} />
            </HoverWrapper>
          </div>
        </ChoosePageImage>
      </div>
      {/* {
        isAtLeastItemSwiped && (
          <BottomMenu
            menuClosedContent={(
              <>
                <Text size="m" color="white" align="center">wow! you’ve a strong opinion!</Text>
                <Text
                  size="m"
                  color="red"
                  onClick={Navigator.navigateToContactForm}
                  align="center"
                >
                    share your choice with us
                </Text>
              </>
            )}
          />
        )
      } */}
    </Layout>
  );
}
