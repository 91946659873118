import { css, cx } from '@emotion/css';

const baseHowerWrapperStyle = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'opacity .3s ease-in',
  opacity: 0
});

const hoverVisibleStyle = css({
  ['&:hover']: {
    opacity: 1
  }
});

const visibleStyle = css({
  opacity: 1
});

export const desktopHowerWrapperStyle = cx(baseHowerWrapperStyle, hoverVisibleStyle);

export const mobileHoverWrapperStyle = (isVisible: boolean): string =>
  isVisible ? cx(baseHowerWrapperStyle, visibleStyle) : baseHowerWrapperStyle;
