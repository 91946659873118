import { BehaviorSubject } from 'rxjs';
import type { ImageLoaderService } from './image-loader.service';
import type { StaticProjectsStorage } from './static-projects.storage';
import type { UserDecisionStorageService } from './user-decision-storage.service';
import { UserSwipe } from '../enums';
import { ImageFileName } from '../strict-strings';
import {
  ISyndicateTransport,
  TGetUserSendedSelectionResponse
} from '../transport';
import {
  TSelectionProjectItem,
  TUserChoiseItem
} from '../types';
import { geIImagePath } from '../utils';

type TResultsPageData = Record<UserSwipe, Array<TSelectionProjectItem>>;

type TServiceData = {
  data: TResultsPageData | void;
  error: Error | void;
};
export class ResultsPageService {
  private static initialData: TServiceData = { data: void 0, error: void 0 };
  private selectedId: string | null = null;
  private rejectedId: string | null = null;

  readonly data$ = new BehaviorSubject<TServiceData>(ResultsPageService.initialData);
  readonly state$ = new BehaviorSubject<UserSwipe>(UserSwipe.SELECT);

  constructor(
    private transport: ISyndicateTransport,
    private storage: UserDecisionStorageService,
    private staticProjectsStorage: StaticProjectsStorage,
    private imageLoaderService: ImageLoaderService
  ) {}

  getData(selectedId: string | null, rejectedId: string | null): void {
    this.selectedId = selectedId;
    this.rejectedId = rejectedId;

    if (!(selectedId && rejectedId)) {
      const data = this.getDataFromStorage();

      this.data$.next({ data, error: void 0 });

      return;
    }

    void this.transport.getUserSendedSelection(selectedId, rejectedId)
      .then((response: TGetUserSendedSelectionResponse) => {
        void this.imageLoaderService.fetchImages(response[UserSwipe.SELECT]
          .concat(response[UserSwipe.REJECT])
          .map(geIImagePath));

        const data = {
          [UserSwipe.SELECT]:
            response[UserSwipe.SELECT]
              .map((photo: ImageFileName) => this.staticProjectsStorage.getSelectionItemByPhoto(photo)),
          [UserSwipe.REJECT]:
            response[UserSwipe.REJECT]
              .map((photo: ImageFileName) => this.staticProjectsStorage.getSelectionItemByPhoto(photo))
        };

        this.data$.next({ data, error: void 0 });
      });
  }

  switchState(state: UserSwipe): void {
    const prevState = this.state$.getValue();

    if (prevState !== state) {
      this.state$.next(state);
    }
  }

  getResultsCount(): { selectedCount: number; rejectedCount: number } {
    if (this.selectedId || this.rejectedId) {
      return {
        selectedCount: this.data$.getValue().data?.[UserSwipe.SELECT].length || 0,
        rejectedCount: this.data$.getValue().data?.[UserSwipe.REJECT].length || 0
      };
    }

    const data = this.getDataFromStorage();

    return {
      selectedCount: data[UserSwipe.SELECT].length,
      rejectedCount: data[UserSwipe.REJECT].length
    };
  }

  clearDataFromStorage = (): void => {
    this.storage.clear();
    this.data$.next({ data: { [UserSwipe.REJECT]: [], [UserSwipe.SELECT]: [] }, error: void 0 });
  };

  private getDataFromStorage(): TResultsPageData {
    const data = this.storage.getItems();

    const result: TResultsPageData = {
      [UserSwipe.SELECT]: [],
      [UserSwipe.REJECT]: []
    };

    data.forEach(({ photo, decision }: TUserChoiseItem) => {
      const project = this.staticProjectsStorage.getSelectionItemByPhoto(photo);

      result[decision].push(project);
    });


    return result;
  }
}
