export enum Colors {
  BLACK = 'rgb(0, 0, 0)',
  WHITE = 'rgb(255, 255, 255)',
  RED = 'rgb(255, 92, 0)',
  GREY = 'rgb(38, 38, 38)',
  DARK_GREY = 'rgb(26, 26, 26)'
}

export enum FontSizePx {
  M = 10,
  L = 30
}

export enum LineHeightPx {
  M = 14,
  L = 27
}
