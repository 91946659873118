import { allProjectsService, useRx } from 'common';
import { EmptyBlock, Text } from 'components';
import React from 'react';
import { currentHashtagStyle } from './all-projects-page.style';

export function CurrentHashTag(): JSX.Element {
  const hashtag = useRx(allProjectsService.currentHashTag$);

  return (
    <div className={currentHashtagStyle}>
      <Text color="red">{hashtag}</Text>
      <EmptyBlock width="16px" />
      <Text cursor="pointer" color="white">filter</Text>
    </div>
  );
}
