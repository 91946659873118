import { BehaviorSubject } from 'rxjs';

export class FormControl<T, K extends string> {
  readonly value$: BehaviorSubject<T>;
  readonly error$: BehaviorSubject<K>;
  readonly touched$ = new BehaviorSubject(false);

  constructor(private initialValue: T, private validator: (value: T) => K) {
    this.value$ = new BehaviorSubject(this.initialValue);
    this.error$ = new BehaviorSubject(validator(initialValue));
  }

  get value(): T {
    return this.value$.getValue();
  }

  update = (value: T): void => {
    const error = this.validator(value);

    this.value$.next(value);
    this.error$.next(error);
  };

  touch = (): void => this.touched$.next(true);
}
