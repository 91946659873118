import { analytics, TProjects } from 'common';
import {
  BottomMenu,
  Layout,
  ProjectItem,
  ColumnLayout
} from 'components';
import { TProjectModel } from 'projects';
import React from 'react';
import { CurrentHashTag } from './current-hashtag';
import { HashtagsSheet } from './hashtags-sheet';

type TAllProjectsPageProps = {
  projects: TProjects;
};

export function AllProjectsPageContent({ projects }: TAllProjectsPageProps): JSX.Element {
  React.useEffect(() => {
    analytics.onViewPage('all projects');
  }, []);

  return (
    <Layout>
      <ColumnLayout>
        {projects.map((project: TProjectModel) => (
          <ProjectItem
            key={project.id}
            id={project.id}
            projectName={project.name}
            imageUrl={project.cover}
          />
        )
        )}
      </ColumnLayout>
      <BottomMenu
        menuClosedContent={<CurrentHashTag />}
        menuOpenedContent={<HashtagsSheet />}
      />
    </Layout>
  );
}
