import type { ImgCacheService } from './img-cache.service';
import { ImagePath } from '../strict-strings';

const maxFetchCount = 5;
const fetchTimeoutMS = 2000;

export class ImageLoaderService {
  constructor(private imageCacheService: ImgCacheService) {}

  isImageLoaded(imagePath: ImagePath): boolean {
    return this.imageCacheService.isImageLoaded(imagePath);
  }

  fetchImage(imageUrl: ImagePath): Promise<boolean> {
    if (this.imageCacheService.isImageLoaded(imageUrl)) {
      return Promise.resolve(true);
    }

    const processingPromise = this.imageCacheService.getImageLoadingPromise(imageUrl);

    if (processingPromise) return processingPromise;

    const fetchPromise = new Promise((resolve: (val: boolean) => void) => {
      const image = new Image();

      image.onload = () => {
        this.imageCacheService.setImageLoaded(imageUrl);

        resolve(true);
      };

      image.onerror = () => {
        this.imageCacheService.setImageFailureLoaded(imageUrl);

        const failureFetchCount = this.imageCacheService.getImageFetchCount(imageUrl);

        if (failureFetchCount < maxFetchCount) {
          this.imageCacheService.deleteImageLoadingPromise(imageUrl);
          setTimeout(() => void this.fetchImage(imageUrl), fetchTimeoutMS);

          return;
        }

        resolve(true);
      };
      image.src = imageUrl.valueOf();
    });

    this.imageCacheService.setImageLoadingPromise(imageUrl, fetchPromise);

    return fetchPromise;
  }

  fetchImages(images: Array<ImagePath>, currIndex: number = 0): void {
    const image = images[currIndex];

    if (image) {
      this.fetchImage(image).finally(() => void this.fetchImages(images, currIndex + 1));
    }
  }
}
