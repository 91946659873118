import { ImagePath, Base64ImagePlaceholder } from '../strict-strings';

export class ImageStorage {
  private storage = new Map<string, Base64ImagePlaceholder>();

  setImageBase64Data(imageUrl: ImagePath, base64data: Base64ImagePlaceholder): void {
    this.storage.set(imageUrl.valueOf(), base64data);
  }

  getImageBase64Data(imageUrl: ImagePath): Base64ImagePlaceholder | ImagePath {
    return this.storage.get(imageUrl.valueOf()) || imageUrl;
  }
}
