import { css } from '@emotion/css';
import {
  fadeInKeyFrames,
  headerMenuHeightPX,
  mobileMediaQuery
} from '../consts';
import { Colors } from '../enums';

const openedPadding = 30;
const closedPadding = 20;
const desktopHeightOpened = 449;
const desktopWidthOpened = 490;
const desktopWidthClosed = 223;
const fromHeaderToSheet = 27;

export const bottomMenuStyle = (isOpened: boolean, initialHeight: number): string => css({
  position: 'fixed',
  bottom: 0,
  left: '50%',
  width: isOpened ? desktopWidthOpened : desktopWidthClosed,
  height: isOpened ? desktopHeightOpened : initialHeight,
  cursor: isOpened ? 'default' : 'pointer',
  backgroundColor: Colors.BLACK,
  transition: `width 200ms ease-in ${isOpened ? '' : '300ms'}, height 200ms ease-in ${isOpened ? '300ms' : ''}`,
  paddingTop: isOpened ? openedPadding : closedPadding,
  paddingBottom: isOpened ? openedPadding : closedPadding,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  transform: 'translate3d(-50%,0,0)',
  willChange: 'width, height',

  [mobileMediaQuery]: {
    width: isOpened ? '100%' : desktopWidthClosed,
    height: isOpened ? `calc(100% - ${headerMenuHeightPX + openedPadding * 2 + fromHeaderToSheet}px)` : initialHeight
  }
});

export const openedMenuStyle = css({
  opacity: 0,
  animation: `${fadeInKeyFrames} 200ms ease-in forwards`

});
