import {
  Navigator,
  scrollTopService,
  UserSwipe
} from 'common';
import { Text } from 'components';
import React from 'react';

type TResultSwitcherProps = {
  onClear: VoidFunction | undefined;
  view: UserSwipe;
  selectedCount: number;
  rejectedCount: number;
};

export function ResultsMenuSheet({ onClear, view, selectedCount, rejectedCount }: TResultSwitcherProps): JSX.Element {
  const handleNavigateToLikes = React.useCallback(() => {
    Navigator.navigateToLikes();
    scrollTopService.scrollTop();
  }, []);

  const handleNavigateToSecretDesires = React.useCallback(() => {
    Navigator.navigateToSecretDesires();
    scrollTopService.scrollTop();
  }, []);

  const handleClear = React.useCallback(() => {
    onClear && onClear();
  }, [onClear]);

  return (
    <div>
      <Text
        align="center"
        onClick={handleNavigateToLikes}
        size="l"
        color={view === UserSwipe.SELECT ? 'red' : 'white'}
        nohover={view === UserSwipe.SELECT}
      >
        your likes {selectedCount}
      </Text>
      <Text
        align="center"
        onClick={handleNavigateToSecretDesires}
        size="l"
        color={view === UserSwipe.REJECT ? 'red' : 'white'}
        nohover={view === UserSwipe.REJECT}
      >
        your secret desires {rejectedCount}
      </Text>
      {onClear && <Text
        size="l"
        onClick={handleClear}
        align="center"
      >
        clear all
      </Text>}
    </div>
  );
}
