import React from 'react';
import { imageStorage, imageLoaderService } from '../services';
import { Base64ImagePlaceholder, ImagePath } from '../strict-strings';

type TUsePlaceholderImgResult = {
  pixelated: boolean;
  currentSrc: ImagePath | Base64ImagePlaceholder;
};

export function usePlaceholderImg(imgSrc: ImagePath, isInView: boolean = true): TUsePlaceholderImgResult {
  const isImageAlreadyLoaded = React.useMemo(() => imageLoaderService.isImageLoaded(imgSrc), [imgSrc]);

  const [pixelated, setPixelated] = React.useState(!isImageAlreadyLoaded);

  const [currentSrc, setCurrentSrc]
    = React.useState<ImagePath | Base64ImagePlaceholder>((): ImagePath | Base64ImagePlaceholder => {
      if (!isImageAlreadyLoaded) return imageStorage.getImageBase64Data(imgSrc);

      return imgSrc;
    });
  const [isMounted, setIsMounted] = React.useState(true);

  React.useEffect(() => () => setIsMounted(false), []);

  React.useEffect(() => {
    if (isMounted && isInView) {
      setPixelated(!isImageAlreadyLoaded);
      setCurrentSrc((): ImagePath | Base64ImagePlaceholder => {
        if (!isImageAlreadyLoaded) return imageStorage.getImageBase64Data(imgSrc);

        return imgSrc;
      });

      imageLoaderService.fetchImage(imgSrc).finally(() => {
        setPixelated(false);
        setCurrentSrc(imgSrc);
      });
    }
  }, [imgSrc, isMounted, isInView, isImageAlreadyLoaded]);

  return { pixelated, currentSrc };
}
