import {
  Colors,
  ContentPageLayout,
  EmptyBlock,
  Layout,
  LayoutContent
} from 'components';
import React from 'react';
import { contentStyle } from './page-with-form.style';
import { UserForm } from './user-form';

type TPageWithFormProps = {
  children: React.ReactNode;
  bgrColor?: Colors;
  onBackToGame?: VoidFunction;
  rightChild?: JSX.Element;
};

export function PageWithForm({
  children,
  bgrColor,
  onBackToGame,
  rightChild
}: TPageWithFormProps): JSX.Element {
  return (
    <Layout ignoreHeader={false} bgrColor={bgrColor}>
      <LayoutContent height="auto">
        <ContentPageLayout>
          <div className={contentStyle}>
            {children}
            <EmptyBlock height="40px" />
            <UserForm onBackToGame={onBackToGame} bgrColor={bgrColor} />
          </div>
          {rightChild}
        </ContentPageLayout>
      </LayoutContent>
    </Layout>
  );
}
