import { fromEvent } from 'rxjs';
import type { UserDecisionStorageService } from './user-decision-storage.service';

const MIN_TOUCHES_FOR_RESET = 5;

export class ResetService {
  private keysPressed: Record<string, boolean> = {};

  constructor(private userDecisionStorage: UserDecisionStorageService) {}

  init(): void {
    fromEvent<KeyboardEvent>(document, 'keydown').subscribe(this.saveKeyToMap);
    fromEvent<KeyboardEvent>(document, 'keyup').subscribe(this.deleteKeyFromMap);
    fromEvent<KeyboardEvent>(document, 'keydown').subscribe(this.handleKeyPressToReset);
    fromEvent<TouchEvent>(document, 'touchstart').subscribe(this.handleTouchToReset);
  }

  resetApplication = (): void => {
    if (confirm('Очистить кеш?')) {
      this.userDecisionStorage.clear();
      window.location.href = window.location.origin;
    }
  };

  private handleKeyPressToReset = (event: KeyboardEvent): void => {
    if (this.keysPressed['Enter'] && event.key === 'a') {
      this.resetApplication();
    }
  };

  private handleTouchToReset = (event: TouchEvent): void => {
    if (event.touches.length >= MIN_TOUCHES_FOR_RESET) {
      this.resetApplication();
    }
  };

  private saveKeyToMap = (event: KeyboardEvent): void => {
    this.keysPressed[event.key] = true;
  };

  private deleteKeyFromMap = (event: KeyboardEvent): void => {
    delete this.keysPressed[event.key];
  };
}
