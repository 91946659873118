import { css } from '@emotion/css';
import { mobileMediaQuery } from '../consts';
import { LineHeightPx } from '../enums';
import { TFontSize } from '../types';

export const maxLineCount = 18;

export const textWrapperStyle = css({
  padding: '2px 0',
  boxSizing: 'border-box'
});

export const textFolderStyle = (isFolded: boolean, fontSize: TFontSize): string => css({
  width: '100%',
  height: '100%',

  [mobileMediaQuery]: {
    height: isFolded ? (maxLineCount * (fontSize === 'l' ? LineHeightPx.L : LineHeightPx.M)) : '100%',
    overflow: 'hidden'
  }
});

export const buttonWrapper = (isFolded: boolean): string => css({
  visibility: 'hidden',

  [mobileMediaQuery]: {
    visibility: isFolded ? 'visible' : 'hidden'
  }
});
