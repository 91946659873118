import {
  TAnalyticsDataRequest,
  TGetUserSendedSelectionResponse,
  TSetUserSelectionRequest,
  TSetUserSelectionResponse
} from './models';
import { ISyndicateTransport } from './transport.interface';

class SyndicateMockTransport implements ISyndicateTransport {
  getUserSendedSelection(selectedId: string, rejectedId: string): Promise<TGetUserSendedSelectionResponse> {
    return Promise.resolve({} as TGetUserSendedSelectionResponse);
  }

  setUserSelection(data: TSetUserSelectionRequest): Promise<TSetUserSelectionResponse> {
    return Promise.resolve({} as TSetUserSelectionResponse);
  }

  sendAnalytics(data: TAnalyticsDataRequest): boolean {
    return true;
  }

  sendError(
    fingerPrint: string,
    errorMessage: string,
    errorStack: string,
    componentStack: string
  ): Promise<void> {
    return Promise.resolve();
  }
}

export const syndicateMockTransport = new SyndicateMockTransport();
