import { css, cx, keyframes } from '@emotion/css';
import {
  Colors,
  fadeInKeyFrames,
  generalMenuWidthPX,
  headerMenuHeightPX
} from 'components';

const animation = keyframes`
  0% { height: 100% };
  100% { height: 50% };
`;

const headerAnimation = keyframes`
  0% {
    transform: translate3d(0, ${window.innerHeight / 2 - headerMenuHeightPX / 2}px, 0);
  };
  100% {
    transform: translate3d(0, 0, 0);
  }
`;

const headerAnimation1 = keyframes`
  0% { width: 100% };
  100% { width: 0% };
`;

export const introPageNoClickBlock = css({
  position: 'fixed',
  width: '100%',
  height: '100%',
  zIndex: 5
});

export const introPageStyle = css({
  position: 'fixed',
  width: '100%',
  height: '100%',
  bottom: 0,
  backgroundColor: Colors.BLACK,
  animation: `${animation} 1s ease-out 2s forwards`,
  overflow: 'hidden'
});

export const introPageContentStyle = css({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: window.innerHeight,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column'
});

export const textStyle = css({
  width: generalMenuWidthPX,
  transform: 'translateY(25%)',
  display: 'flex',
  flexDirection: 'column'
});

export const textFirstParagraphStyle = css({
  opacity: 0,
  animation: `${fadeInKeyFrames} 0.5s ease-in 2.5s forwards`
});

export const textSecondParagraphStyle = css({
  opacity: 0,
  animation: `${fadeInKeyFrames} 0.5s ease-in 3s forwards`
});

export const introHeaderStyle = css({
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  height: headerMenuHeightPX - 20,
  width: generalMenuWidthPX,
  transform: `translate3d(0, ${window.innerHeight / 2 - headerMenuHeightPX / 2}px, 0)`,
  padding: '20px',
  boxSizing: 'border-box',
  background: Colors.WHITE,
  animation: `${headerAnimation} 1s 1s ease-out forwards`,

  ['::before']: {
    position: 'absolute',
    top: 0,
    right: 0,
    content: '""',
    height: '100%',
    background: Colors.BLACK,
    animation: `${headerAnimation1} 1s ease-in-out forwards`
  }
});

export const buttonsWrapperStyle = css({
  opacity: 0.5
});

export const introButtonStyle = cx(textSecondParagraphStyle, css({
  border: 0,
  padding: '4px 8px 2px',
  margin: '0 auto',
  backgroundColor: Colors.RED,
  cursor: 'pointer',
  position: 'relative',

  ['::before']: {
    content: '""',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute'
  }
}));
