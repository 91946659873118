import { css } from '@emotion/css';
import {
  generalMenuWidthPX,
  sheetWidthPX,
  fadeInKeyFrames,
  mobileMediaQuery,
  headerMenuHeightPX
} from '../consts';

export const headerMenuStyle = (isMenuOpened: boolean): string => css({
  position: 'fixed',
  top: 0,
  left: '50%',
  backgroundColor: '#fff',
  width: isMenuOpened ? sheetWidthPX : generalMenuWidthPX,
  height: isMenuOpened ? window.innerHeight : headerMenuHeightPX,
  transition: `width 200ms ease-in ${isMenuOpened ? '' : '300ms'}, height 200ms ease-in ${isMenuOpened ? '300ms' : ''}`,
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: '20px',
  margin: '0 auto',
  transform: 'translate3d(-50%,0,0)',
  willChange: 'width, height',

  [mobileMediaQuery]: {
    width: isMenuOpened ? '100%' : generalMenuWidthPX
  }
});

export const menuItemsStyle = css({
  opacity: 0,
  animation: `${fadeInKeyFrames} 200ms ease-in 300ms forwards`
});

