import { css } from '@emotion/css';
import { TTextProps } from './text.props-type';
import {
  FontSizeMap,
  LineHeightMap,
  TextColorMap,
  FontFamilyMap
} from '../consts';
import { Colors } from '../enums';

type TRequiredTextProps = Required<TTextProps>;

const hoverColorMap: Record<TRequiredTextProps['color'], string> = {
  'black': Colors.RED,
  'white': Colors.RED,
  'red': Colors.WHITE
};

export const textStyle = (
  size: TRequiredTextProps['size'],
  color: TRequiredTextProps['color'],
  action: boolean,
  align: TRequiredTextProps['align'],
  cursor: TTextProps['cursor'],
  nohover: boolean = false
): string => css({
  fontSize: FontSizeMap[size],
  fontFamily: FontFamilyMap[size],
  color: TextColorMap[color],
  cursor: cursor ? cursor : action ? 'pointer' : 'auto',
  textAlign: align,
  lineHeight: LineHeightMap[size],
  letterSpacing: size === 'm' ? '0.6px' : undefined,

  [':hover']: {
    color: action && !nohover ? hoverColorMap[color] : undefined
  }
});
