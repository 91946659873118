import { analytics, Navigator } from 'common';
import { Text } from 'components';
import React from 'react';
import { PageWithForm } from './page-with-form';

export function ContactUsPage(): JSX.Element {
  React.useEffect(() => {
    analytics.onViewPage('contact');
  }, []);

  return (
    <PageWithForm onBackToGame={Navigator.navigateToGame}>
      <Text size="l">
        send&nbsp;us your contact and we&rsquo;ll get back to&nbsp;you shortly (like, really shortly)
      </Text>
    </PageWithForm>
  );
}
