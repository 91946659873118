import {
  allProjectsService,
  scrollTopService,
  useRx
} from 'common';
import { Text } from 'components';
import React from 'react';

type THashtagProps = {
  hashtag: string;
  isActive: boolean;
  onClick: (hashtag: string) => void;
};

function Hashtag({ hashtag, isActive, onClick }: THashtagProps): JSX.Element {
  const handleClick = React.useCallback(() => {
    onClick(hashtag);
    scrollTopService.scrollTop();
  }, [onClick, hashtag]);

  return (
    <Text align="center" onClick={handleClick} size="l" color={isActive ? 'red' : 'white'}>{hashtag}</Text>
  );
}

export function HashtagsSheet(): JSX.Element {
  const hastags = useRx(allProjectsService.hashtags$);
  const currentHashtag = useRx(allProjectsService.currentHashTag$);

  return (
    <>
      {hastags.map((hashtag: string) => (
        <Hashtag
          key={hashtag}
          onClick={allProjectsService.setHashtag}
          hashtag={hashtag}
          isActive={hashtag === currentHashtag}
        />
      ))}
    </>
  );
}
