import React from 'react';
import { fromEvent } from 'rxjs';

export function useOnClickOutside(ref: React.MutableRefObject<null | HTMLElement>, handler: VoidFunction): void {
  React.useEffect(
    () => {
      const listener = (event: Event): void => {
        if (!ref.current || event.target && ref.current.contains(event.target as Node)) {
          return;
        }
        handler();
      };
      const sub = fromEvent(document, 'mousedown').subscribe(listener);

      return () => sub.unsubscribe();
    },
    [ref, handler]
  );
}
