/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { TProjectModel, TProjectRaw } from 'projects';
import { BehaviorSubject } from 'rxjs';
import type { ImageStorage } from './image.storage';
import { ImageType } from '../enums';
import { Base64ImagePlaceholder, ImageFileName, ImagePath } from '../strict-strings';
import { TSelectionProjectItem } from '../types';
import { extracIImageFileName, geIImagePath, getImageNameFromPath } from '../utils';

const numberRegexp = /\d+/;

type TMappedKeys = {
  project: Omit<TProjectRaw, 'photos'>;
  photos: TProjectModel['photos'];
};

type TRequirePhotoContextRes = {
  src: string;
  preSrc: string;
};

export class StaticProjectsStorage {
  readonly projects$ = new BehaviorSubject<Array<TProjectModel>>([]);

  constructor(private imageStorage: ImageStorage) {
    this.getProjects();
  }

  getProjectById(projectId: string): TProjectModel {
    return this.projects$.getValue().find(({ id }: TProjectModel) => id === projectId) as TProjectModel;
  }

  getProjectByPhoto(photo: ImageFileName): TProjectModel {
    const { projectId } = extracIImageFileName(photo);

    return this.getProjectById(projectId);
  }

  getSelectionItemByPhoto(photo: ImageFileName): TSelectionProjectItem {
    const project = this.getProjectByPhoto(photo);

    return {
      photo: geIImagePath(photo),
      projectId: project.id,
      projectName: project.name
    };
  }

  private getProjects(): void {
    const required = this.requireProjectsAndPhotos();
    const projects = this.mapRequiredInfoToProjectModel(required);

    this.projects$.next(projects);
  }

  private requireProjectsAndPhotos(): Record<string, TMappedKeys> {
    const result: Record<string, TMappedKeys> = {};

    const r = require.context('projects', true, /\.(json|png|jpe?g|svg)$/);

    r.keys().forEach((path: string) => {
      const projectId = this.getProjectIdFromPath(path);

      if (!result[projectId]) {
        result[projectId] = {
          project: {} as Omit<TProjectRaw, 'photos'>,
          photos: []
        };
      }

      if (path.includes('json')) {
        result[projectId].project = r(path) as Omit<TProjectRaw, 'photos'>;
      }

      if (path.includes('photos')) {
        const { src, preSrc } = r<TRequirePhotoContextRes>(path);

        const typedSrc = new ImagePath(src);
        const typedPreSrc = new Base64ImagePlaceholder(preSrc);

        this.imageStorage.setImageBase64Data(typedSrc, typedPreSrc);

        result[projectId].photos.push(src);
      }
    });

    return result;
  }

  private mapRequiredInfoToProjectModel(requiredInfo: Record<string, TMappedKeys>): Array<TProjectModel> {
    return Object.values<TMappedKeys>(requiredInfo)
      .map(({ project, photos }: TMappedKeys) => {
        const cover = geIImagePath(project.cover);

        const selection = project.selection.map(geIImagePath);

        const sortedPhotos = photos.sort((a: ImagePath, b: ImagePath) => {
          const aImgName = getImageNameFromPath(a);
          const bImgName = getImageNameFromPath(b);
          const { type: aType, index: aIndex } = extracIImageFileName(aImgName);
          const { type: bType, index: bIndex } = extracIImageFileName(bImgName);

          if (aType === ImageType.DRAW && bType === ImageType.IMAGE) return 1;
          if (aType === ImageType.IMAGE && bType === ImageType.DRAW) return -1;

          return aIndex - bIndex;
        });


        return { ...project, cover, selection, photos: sortedPhotos } as TProjectModel;
      });
  }

  private getProjectIdFromPath(path: string): string {
    const match = numberRegexp.exec(path);

    return match ? match[0] : '';
  }
}
