import { css, keyframes } from '@emotion/css';

const dotsAnimationKeyframes = keyframes`
  0%, 20% { content: '.'; }
  40% { content: '..'; }
  60% { content: '...'; }
  90%, 100% { content: '' }
`;

export const sendingStateButton = css({
  ['::after']: {
    content: '""',
    animation: `${dotsAnimationKeyframes} 1s linear infinite`
  }
});
