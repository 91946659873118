import React from 'react';
import {
  columnLayoutDesktopStyle,
  leftColumnStyle,
  rightColumnStyle
} from './column-layout.style';
import { TColumnLayoutProps } from './props.type';

export function ColumnLayoutDesktop({ children }: TColumnLayoutProps): JSX.Element {
  const { left, right } = React.useMemo(() => {
    const leftColumn: Array<React.ReactNode> = [];
    const rightColumn: Array<React.ReactNode> = [];

    React.Children.forEach(children, (child: React.ReactNode, index: number) => {
      if (index % 2 === 0) {
        leftColumn.push(child);
      } else {
        rightColumn.push(child);
      }
    });

    return { left: leftColumn, right: rightColumn };
  }, [children]);

  return (
    <div className={columnLayoutDesktopStyle}>
      <div className={leftColumnStyle}>{left}</div>
      <div className={rightColumnStyle}>{right}</div>
    </div>
  );
}
