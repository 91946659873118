import { useMobile } from 'common';
import React from 'react';
import { ColumnLayoutDesktop } from './column-layout-desktop';
import { ColumnLayoutMobile } from './column-layout-mobile';
import { TColumnLayoutProps } from './props.type';

export function ColumnLayout({ children }: TColumnLayoutProps): JSX.Element {
  const isMobile = useMobile();

  if (isMobile) {
    return (
      <ColumnLayoutMobile>{children}</ColumnLayoutMobile>
    );
  }

  return (
    <ColumnLayoutDesktop>{children}</ColumnLayoutDesktop>
  );
}
