import {
  Agent,
  GetResult,
  load
} from '@fingerprintjs/fingerprintjs';
import { StorageService } from './storage.service';

export class FingerPrintService {
  private fingerprintJS = load();
  private storage = StorageService.makeInstance('fingerPrint', '');
  private userFingerprint: string = '';

  constructor() {
    const savedFingerPrint = this.storage.getState();

    if (!savedFingerPrint) {
      void this.getFingerPrint().then((fingerPrint: string) => {
        this.storage.setState(fingerPrint);
      });
    } else {
      this.userFingerprint = savedFingerPrint;
    }
  }

  getFingerPrint(): Promise<string> {
    if (!this.userFingerprint) {
      return this.fingerprintJS.then((agent: Agent) => agent.get().then((result: GetResult) => {
        this.userFingerprint = result.visitorId;

        return this.userFingerprint;
      }));
    }

    return Promise.resolve(this.userFingerprint);
  }
}
