import {
  analytics,
  routerHistory,
  routerPaths,
  useMobile
} from 'common';
import { ErrorBoundary, Header } from 'components';
import {
  AllProjectsPage,
  ContactUsPage,
  ChoosePage,
  ProjectPage,
  ResultsPage,
  StudioPage,
  MatchPage
} from 'pages';
import React from 'react';
import {
  Router,
  Route,
  Switch
} from 'react-router';

export function SyndicatePage(): JSX.Element {
  const isMobile = useMobile();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => analytics.onInit(isMobile ? 'mobile' : 'desktop'), []);

  return (
    <ErrorBoundary>
      <Header />
      <Router history={routerHistory}>
        <Switch>
          <Route path={routerPaths.MAIN} exact component={ChoosePage} />
          <Route path={routerPaths.STUDIO} exact component={StudioPage} />
          <Route path={routerPaths.PROJECTS} exact component={AllProjectsPage} />
          <Route path={routerPaths.USER_CHOOSE_RESULTS} exact component={ResultsPage} />
          <Route path={routerPaths.PROJECT} component={ProjectPage} />
          <Route path={routerPaths.CONTACT_US} component={ContactUsPage} />
          <Route path={routerPaths.ITS_A_MATCH} component={MatchPage} />
        </Switch>
      </Router>
    </ErrorBoundary>
  );
}
