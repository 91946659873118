import { css } from '@emotion/css';
import { TLinkProps } from './link.props-type';
import {
  FontFamilyMap,
  LineHeightMap,
  FontSizeMap,
  TextColorMap
} from '../consts';

type TRequiredLinkProps = Required<TLinkProps>;

export const linkStyle = (
  size: TRequiredLinkProps['size'],
  color: TRequiredLinkProps['color'],
  hoverColor: TRequiredLinkProps['hoverColor']
): string => css({
  textDecoration: 'none',
  color: TextColorMap[color],
  fontFamily: FontFamilyMap[size],
  fontSize: FontSizeMap[size],
  lineHeight: LineHeightMap[size],
  cursor: 'pointer',

  [':hover']: {
    color: TextColorMap[hoverColor]
  }
});
