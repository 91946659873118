import React from 'react';

let zIndex = 10;

export function useZIndex(mutableVal?: unknown): number {
  React.useEffect(() => {
    if (mutableVal !== undefined) {
      zIndex += 1;

      return () => {
        zIndex -= 1;
      };
    }
  }, [mutableVal]);

  React.useEffect(() => {
    zIndex += 1;
  }, []);

  return zIndex;
}
