import { analytics, Navigator, useMobile } from 'common';
import {
  Colors,
  EmptyBlock,
  Link,
  Text
} from 'components';
import { PageWithForm } from 'pages/page-with-form';
import React from 'react';

export function MatchPage(): JSX.Element {
  const isMobile = useMobile();

  React.useEffect(() => {
    analytics.onViewPage('its-a-match');
  }, []);

  return (
    <PageWithForm
      bgrColor={Colors.RED}
      rightChild={
        <>
          {isMobile && <EmptyBlock height="60px" />}
          <Link
            color="black"
            size="l"
            onClick={Navigator.navigateToGame}
          >
            go&nbsp;back&nbsp;to&nbsp;game
          </Link>
        </>
      }
    >
      <Text size="l" color="white">
        it&rsquo;s a&nbsp;match!
      </Text>
      <Text size="l" color="black">
        it&nbsp;seems we&nbsp;like each other. let&rsquo;s build amazing things together.
      </Text>
      <EmptyBlock height="60px" />
      <Text size="l" color="black">
        send&nbsp;us your details and we&rsquo;ll get back to&nbsp;you shortly (like, really shortly)
      </Text>
      <EmptyBlock height="20px" />
    </PageWithForm>
  );
}
