import { css } from '@emotion/css';
import { Colors } from '../enums';

export const projectDetailedStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%'
});

export const projectItemStyle = css({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const projectImgStyle = css({
  width: '100%',
  backgroundColor: Colors.WHITE
});
