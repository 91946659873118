import {
  resultsPageService,
  TSelectionProjectItem,
  userDecisionService,
  UserSwipe,
  useRx,
  useSearchParams
} from 'common';
import { ProcessingWrapper } from 'components';
import React from 'react';
import { ResultsPageContent } from './results-page-content';

export function ResultsPage(): JSX.Element {
  const { data, error } = useRx(resultsPageService.data$);
  const { selectedId, rejectedId } = useSearchParams(['selectedId', 'rejectedId']);

  const handleClear = React.useCallback(() => {
    resultsPageService.clearDataFromStorage();
    userDecisionService.reset();
  }, []);

  React.useEffect(() => {
    resultsPageService.getData(selectedId, rejectedId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProcessingWrapper error={error} data={data}>
      {(readyData: Record<UserSwipe, Array<TSelectionProjectItem>>) => (
        <ResultsPageContent
          data={readyData}
          onClear={(selectedId || rejectedId) ? void 0 : handleClear}
        />
      )}
    </ProcessingWrapper>
  );
}
