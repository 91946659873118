import React from 'react';
import { iconStyle } from './detailed.style';

type TPlusProps = {
  className: string;
};

export function Plus({ className }: TPlusProps): JSX.Element {
  return (
    <svg className={iconStyle} width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="9" height="9" className={className} />
      <rect x="4" y="2" width="1" height="5" fill="white"/>
      <rect x="7" y="4" width="1" height="5" transform="rotate(90 7 4)" fill="white"/>
    </svg>
  );
}
