import { css } from '@emotion/css';
import { FontSizeMap, LineHeightMap } from '../consts';
import { Colors } from '../enums';

export const inputStyle = (bgrColor?: Colors): string => css({
  fontSize: FontSizeMap.l,
  width: '100%',
  color: Colors.WHITE,
  height: LineHeightMap.l,
  paddingTop: '1px',
  background: 'none',
  boxSizing: 'border-box',
  fontFamily: 'HelveticaNeue-Bold, HelveticaNeueLTCYR-BdCond',

  [':focus']: {
    outline: 'none'
  },

  ['::placeholder']: {
    color: bgrColor === Colors.RED ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.3)',
    fontSize: FontSizeMap.l
  }
});
