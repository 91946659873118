import { css } from '@emotion/css';
import { mobileMediaQuery } from '../consts';

export const contentPageLayoutStyle = (count: number): string => css({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  paddingTop: '80px',
  paddingBottom: '60px',

  ['> div:first-child']: {
    flexBasis: '80%',
    paddingRight: '30px',

    [mobileMediaQuery]: {
      paddingRight: 0
    }
  },

  ['> div:last-child']: {
    flexBasis: count === 1 ? '80%' : '20%'
  },

  [mobileMediaQuery]: {
    flexDirection: 'column'
  }
});

const fullWidth = 100;

export const contentPageLayoutList = (count: number): string => css({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  ['> div']: {
    flexBasis: `${fullWidth / count}%`,

    [mobileMediaQuery]: {
      flexBasis: `${fullWidth / (count - 1)}%`,
      marginBottom: '40px'
    }
  }
});
