import axios, {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';

type TErrorResponse = {
  status: 'error';
  response: string;
};

type TSuccesResponse<T> = {
  status: 'ok';
  response: T;
};

type TResponse<T> = TErrorResponse | TSuccesResponse<T>;

type TPostConfig = {
  url: string;
  method: string;
  params?: object;
  config?: AxiosRequestConfig;
};

class Transport {
  private instance = axios.create();

  get(url: string): Promise<unknown> {
    return this.instance.get(url);
  }

  post<T>({ url, method, params = {}, config = {} }: TPostConfig): Promise<T> {
    const request = {
      method,
      params
    };

    return this.instance.post(
      url,
      request,
      { headers: { 'Content-Type': 'text/plain;charset=utf-8' }, ...config }
    ).then(({ data }: AxiosResponse<TResponse<T>>) => {
      if (data.status === 'ok') return data.response;

      return Promise.reject(data.response || 'Network Error');
    });
  }

  sendBeacon({ url, method, params = {} }: TPostConfig): boolean {
    const request = {
      method, params
    };

    return navigator.sendBeacon(url, JSON.stringify(request));
  }
}

export const transport = new Transport();
