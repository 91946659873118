import { ImagePath } from 'common';
import {
  EmptyBlock,
  Layout,
  LayoutContent,
  Text,
  ContentPageLayout,
  ContentPageLayoutList,
  TextFolder,
  Img
} from 'components';
import { TProjectModel } from 'projects';
import React from 'react';
import {
  projectInfoStyle,
  projectPhotoStyle
} from './project-page.style';

type TProjectPageContentProps = {
  project: TProjectModel;
};

export function ProjectPageContent({ project }: TProjectPageContentProps): JSX.Element {
  return (
    <Layout ignoreHeader={false}>
      <LayoutContent height="auto">
        <ContentPageLayout>
          <div className={projectInfoStyle}>
            <Text size="l">{project.name}</Text>
            <EmptyBlock height="40px" />
            <TextFolder buttonColor="red" fontSize="l">
              <Text size="l">{project.description}</Text>
            </TextFolder>
            <EmptyBlock height="60px" />
            <ContentPageLayoutList>
              <Text>{project.year}</Text>
              <Text>{project.location}, {project.country}</Text>
            </ContentPageLayoutList>
          </div>
          <div>
            {project.hashtags.map((hashtag: string) => <Text key={hashtag} size="l">{hashtag}</Text>)}
          </div>
        </ContentPageLayout>
      </LayoutContent>
      {project.photos.map((photoUrl: ImagePath, index: number) => (
        <Img
          src={photoUrl}
          key={index}
          alt={project.name}
          className={projectPhotoStyle}
        />
      )
      )}
    </Layout>
  );
}
