import {
  FormControl,
  useRx
} from 'common';
import React from 'react';

export type TFormControlChildrenProps<T> = {
  onLeave?: VoidFunction;
  value?: T;
  onChange?: (value: T) => void;
};

type TFormControlFieldProps<T, K extends string> = {
  control: FormControl<T, K>;
  children: React.ReactElement<TFormControlChildrenProps<T>>;
};

export function FormControlField<T, K extends string>({
  control,
  children
}: TFormControlFieldProps<T, K>): JSX.Element {
  const value = useRx(control.value$);

  return (
    <div>
      {
        React.cloneElement(children,
          {
            ...children.props,
            value,
            onLeave: control.touch,
            onChange: control.update
          })
      }
    </div>
  );
}

