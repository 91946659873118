import { css } from '@emotion/css';
import {
  copyrightHeightPX,
  generalMenuWidthPX
} from '../consts';
import { Colors } from '../enums';

export const copyrightStyle = css({
  boxSizing: 'border-box',
  height: copyrightHeightPX,
  overflow: 'hidden'
});

export const copyrightBlockStyle = css({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  width: `${generalMenuWidthPX}px`,
  backgroundColor: Colors.BLACK,
  height: '68px'
});

export const logoBlockStyle = css({
  backgroundColor: Colors.WHITE,
  padding: '20px',
  boxSizing: 'border-box'
});

