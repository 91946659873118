import { css } from '@emotion/css';
import { Colors } from '../enums';

export const iconFillStyle = css({
  fill: Colors.BLACK
});

export const iconStyle = css({
  position: 'relative',
  top: '1px'
});

export const buttonStyle = css({
  display: 'flex',
  position: 'relative',
  [`&:hover .${iconFillStyle}`]: {
    fill: Colors.RED
  }
});

export const detailedStyle = css({
  width: '223px',
  height: '70px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: '#fff'
});
