import {
  projectId,
  projectService,
  useRx
} from 'common';
import { ProcessingWrapper } from 'components';
import { TProjectModel } from 'projects';
import React from 'react';
import { useParams } from 'react-router';
import { ProjectPageContent } from './project-page-content';

type TProjectPageRouterParams = {
  [projectId]: string;
};

export function ProjectPage(): JSX.Element {
  const { data, error } = useRx(projectService.data$);
  const params = useParams<TProjectPageRouterParams>();

  React.useEffect(() => {
    projectService.getProjectInfo(params[projectId]);

    return () => projectService.reset();
  }, [params]);

  return (
    <ProcessingWrapper data={data} error={error}>
      {(readyData: TProjectModel) => <ProjectPageContent project={readyData} />}
    </ProcessingWrapper>
  );
}
