import {
  useRx,
  userDecisionService,
  TSelectionProjectItem,
  UserSwipe,
  useZIndex
} from 'common';
import { LoaderPage } from 'components';
import React from 'react';
import { AllItemsSwipedPage } from './all-items-swiped-page';
import { ChoosePageContent } from './choose-page-content';
import { loaderWrapperStyle } from './choose-page.style';
import { IntroPageWrapper } from '../intro-page';

export function ChoosePage(): JSX.Element {
  const { data } = useRx(userDecisionService.data$);
  const zIndex = useZIndex();

  React.useEffect(() => userDecisionService.getItems(), []);

  const handleSelectItem = React.useCallback((item: TSelectionProjectItem, select: UserSwipe) => {
    userDecisionService.setItem(item, select);
  }, []);

  if (!data) {
    return <div className={loaderWrapperStyle(zIndex)}><LoaderPage /></div>;
  }

  if (data.length === 0) {
    return <AllItemsSwipedPage />;
  }

  return (
    <IntroPageWrapper>
      <ChoosePageContent onSelectItem={handleSelectItem} itemsQueue={data} />
    </IntroPageWrapper>
  );
}
