import { css } from '@emotion/css';
import { Colors } from '../enums';

export const columnLayoutDesktopStyle = css({
  display: 'flex',
  justifyContent: 'stretch',
  height: '100%',
  minHeight: window.innerHeight,

  ['& > div']: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column'
  }
});

export const leftColumnStyle = css({
  // eslint-disable-next-line max-len
  backgroundImage: `repeating-linear-gradient(${Colors.DARK_GREY}, ${Colors.DARK_GREY} 500px, ${Colors.GREY} 500px, ${Colors.GREY} 1005px)`,
  flexGrow: 1
});

export const rightColumnStyle = css({
  // eslint-disable-next-line max-len
  backgroundImage: `repeating-linear-gradient(${Colors.GREY}, ${Colors.GREY} 500px, ${Colors.DARK_GREY} 500px, ${Colors.DARK_GREY} 1205px)`,
  flexGrow: 1
});

export const mobileLayoutStyle = css({
  // eslint-disable-next-line max-len
  backgroundImage: `repeating-linear-gradient(${Colors.DARK_GREY}, ${Colors.DARK_GREY} 500px, ${Colors.GREY} 500px, ${Colors.GREY} 1005px)`,
  height: '100%'
});
