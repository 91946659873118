import { ImagePath } from '../strict-strings';

export class ImgCacheService {
  private successImageLoadCache: Set<string> = new Set();
  private failureImageLoadCache: Map<string, number> = new Map();
  private imageLoadingPromiseCache: Map<string, Promise<boolean>> = new Map();

  isImageLoaded(imagePath: ImagePath): boolean {
    const isLoaded = this.successImageLoadCache.has(imagePath.valueOf());

    if (isLoaded) {
      this.failureImageLoadCache.delete(imagePath.valueOf());
    }

    return isLoaded;
  }

  setImageLoadingPromise(imagePath: ImagePath, imgLoadingPromise: Promise<boolean>): void {
    this.imageLoadingPromiseCache.set(imagePath.valueOf(), imgLoadingPromise);
  }

  deleteImageLoadingPromise(imagePath: ImagePath): void {
    this.imageLoadingPromiseCache.delete(imagePath.valueOf());
  }

  getImageLoadingPromise(imagePath: ImagePath): Promise<boolean> | void {
    const promise = this.imageLoadingPromiseCache.get(imagePath.valueOf());

    if (promise) {
      this.imageLoadingPromiseCache.delete(imagePath.valueOf());
    }

    return promise;
  }

  setImageLoaded(imagePath: ImagePath): void {
    this.successImageLoadCache.add(imagePath.valueOf());
  }

  setImageFailureLoaded(imagePath: ImagePath): void {
    const prevCount = this.failureImageLoadCache.get(imagePath.valueOf()) || 0;

    this.failureImageLoadCache.set(imagePath.valueOf(), prevCount + 1);
  }

  getImageFetchCount(imagePath: ImagePath): number {
    return this.failureImageLoadCache.get(imagePath.valueOf()) || 0;
  }
}
