import { css } from '@emotion/css';
import { Base64ImagePlaceholder, ImagePath } from 'common';
import { Colors, mobileMediaQuery } from 'components';

export const choosePageStyle = css({
  width: '100%',
  height: window.innerHeight
});

export const choosePageImageStyle = (
  background: ImagePath | Base64ImagePlaceholder,
  isPixelated: boolean
): string => css({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundImage: `url('${background.valueOf()}')`,
  backgroundPosition: 'center center',
  backgroundRepeat: 'repeat',
  backgroundSize: 'auto 100%',
  backgroundColor: Colors.WHITE,
  imageRendering: isPixelated ? 'pixelated' : undefined,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [mobileMediaQuery]: {
    backgroundSize: '100% auto'
  }
});

export const detailedWrapperInfo = css({
  width: '50vw',
  height: '70vh',
  zIndex: 5,

  [mobileMediaQuery]: {
    width: '100%'
  }
});

export const loaderWrapperStyle = (zIndex: number): string => css({
  position: 'fixed',
  zIndex,
  width: '100%',
  height: '100%',
  backgroundColor: Colors.BLACK
});
