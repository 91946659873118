import { cx } from '@emotion/css';
import { ImagePath, usePlaceholderImg } from 'common';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { imagePixelatedStyle } from './image.style';

type TImageProps = {
  src: ImagePath;
  alt: string;
  className: string;
};

export function Img({ src, alt, className }: TImageProps): JSX.Element {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  const { currentSrc, pixelated } = usePlaceholderImg(src, inView);

  return (
    <img
      ref={ref}
      src={currentSrc.valueOf()}
      alt={alt}
      className={pixelated ? cx(className, imagePixelatedStyle) : className}
    />
  );
}
